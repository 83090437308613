import {Box, Collapse, IconButton, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import UploadGrey from "../../../../../../assets/icons/UploadGrey.svg";
import UploadRed from "../../../../../../assets/icons/UploadRed.svg";
import Folder from "../../../../../../assets/icons/Folder.svg";
import {UploadImageDialog} from "../../../../../common/Dialog/UploadImage";
import {useApi} from "../../../../../../api/api";
import Dialog from "../../../../../common/Dialog/Dialog";
import Button from "../../../../../common/Button/Button";
import {Tab} from "../../../../../common/Tab/Tab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Switch} from "../../../../../common/Switch/Switch";
import {Slider} from "../../../../../common/Slider/Slider";
import {PICTORIAL_DEFAULT_OBJECT} from "../styleConsts";
import {debounce, cloneDeep} from "lodash";
import {TreeItem, TreeView} from "@mui/x-tree-view";
import {propExistsOnObject} from "../utils";

export const PictorialConfig = ({config, setConfig, chartState, chartPreferences}) => {

    const { postData: postDataWorkspaceActions } = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/workspaceactions');

    const selectedRow = useRef(null);
    const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [editingRow, setEditingRow] = useState(null);

    useEffect(() => {
        getUploadedImages();
    }, []);

    const categories = useMemo(() => {
        return chartState.categoryLegendData.map((item) => item.name)
    }, [chartState.categoryLegendData]);

    const values = useMemo(() => {
        return chartState.categoryAxisValues
    }, [chartState.categoryAxisValues]);

    const setPropValue = (prop, value, categoryIndex, valueIndex) => {
        if (categoryIndex === -1 || valueIndex === -1) return;
        const newConfig = { ...config };
        if (!newConfig.styleOverrides?.pictorialBar?.data) {
            newConfig.styleOverrides = {
                ...newConfig.styleOverrides,
                pictorialBar: {
                    ...newConfig.styleOverrides.pictorialBar,
                    data: []
                }
            };
        }
        if (!newConfig.styleOverrides.pictorialBar.data[categoryIndex]) newConfig.styleOverrides.pictorialBar.data[categoryIndex] = [];
        if (!newConfig.styleOverrides.pictorialBar.data[categoryIndex][valueIndex]) newConfig.styleOverrides.pictorialBar.data[categoryIndex][valueIndex] = { ...PICTORIAL_DEFAULT_OBJECT };
        newConfig.styleOverrides.pictorialBar.data[categoryIndex][valueIndex][prop] = value;
        setConfig(newConfig);
    };

    const debouncedFunc = useCallback(debounce(
        (prop, value, categoryIndex, valueIndex) => {
            if (categoryIndex === -1 || valueIndex === -1) {
                categories?.forEach((_, c) => {
                    values?.forEach((_, v) => {
                        setPropValue(prop, value, c, v);
                    })
                })
            } else {
                setPropValue(prop, value, categoryIndex, valueIndex)
            }
        }, 200), [config])

    const getUploadedImages = () => {
        postDataWorkspaceActions({
            "action": 'listImages',
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        }).then((res) => setUploadedImages(res))
    }

    const getRootPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.pictorialBar, prop)) return config.styleOverrides.pictorialBar[prop]
        if (propExistsOnObject(chartPreferences.pictorialBar, prop)) return chartPreferences.pictorialBar[prop]
        return PICTORIAL_DEFAULT_OBJECT[prop]
    }

    const getPropValue = (prop, categoryIndex, valueIndex) => {
        if (applyToAll) {
            if (propExistsOnObject(config.styleOverrides?.pictorialBar?.data?.[0]?.[0], prop)) return config.styleOverrides.pictorialBar.data[0][0][prop];
            if (propExistsOnObject(chartPreferences.pictorialBar?.data?.[0]?.[0], prop)) return chartPreferences.pictorialBar.data[0][0][prop];
            return PICTORIAL_DEFAULT_OBJECT[prop];
        }
        if (propExistsOnObject(config.styleOverrides?.pictorialBar?.data?.[categoryIndex]?.[valueIndex], prop)) return config.styleOverrides.pictorialBar.data[categoryIndex][valueIndex][prop];
        if (propExistsOnObject(chartPreferences.pictorialBar?.data?.[categoryIndex]?.[valueIndex], prop)) return chartPreferences.pictorialBar.data[categoryIndex][valueIndex][prop];
        return PICTORIAL_DEFAULT_OBJECT[prop];
    };

    const handleDebouncedSetter = (prop, value, setter, valueIndex, categoryIndex) => {
        debouncedFunc(prop, value, categoryIndex, valueIndex)
        setter(value)
    }

    const handleSelectImage = (image) => {
        const imageValue = `image://${image}`;
        setIsSelectDialogOpen(false)
        if (applyToAll) {
            categories?.forEach((_, categoryIndex) => {
                values?.forEach((_, valueIndex) => {
                    setPropValue('symbol', imageValue, categoryIndex, valueIndex);
                })
            })
        } else {
            const { category, value } = selectedRow.current;
            const valueIndex = values?.indexOf(value);
            const categoryIndex = categories?.indexOf(category);
            setPropValue('symbol', imageValue, categoryIndex, valueIndex);
        }
    }

    const handleOpenSelect = (e, category, value) => {
        e.stopPropagation();
        selectedRow.current = {category, value};
        setIsSelectDialogOpen(true)
    }

    const setRootPropValue = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.pictorialBar[prop] = value
        setConfig(newConfig)
    }

    const shouldExpand = (category, value) => {
        if (!editingRow) return false;
        return editingRow.value === value && editingRow.category === category;
    }

    const getSelectedImage = (category, value) => {
        const categoryData = config.styleOverrides.pictorialBar?.data?.[category];
        if (!categoryData) return null;
        const valueData = categoryData?.[value];
        if (!valueData) return null;
        return valueData['symbol'] || null;
    };

    const handlePropChange = (prop, value, categoryIndex, valueIndex) => {
        if (applyToAll) {
            categories?.forEach((_, categoryIndex) => {
                values?.forEach((_, valueIndex) => {
                    setPropValue(prop, value, categoryIndex, valueIndex);
                })
            })
        } else {
            setPropValue(prop, value, categoryIndex, valueIndex)
        }
    }

    const showAsBar = getRootPropValue('showAsBar');
    const applyToAll = getRootPropValue('applyAll');

    return (
        <>
            <Box className={'column'} sx={{flexWrap: 'wrap'}}>
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center', marginBottom: '16px'}}>
                    <Typography className={'inter style-config-label'}>Show as bar</Typography>
                    <Switch
                        checked={showAsBar}
                        onClick={() => setRootPropValue('showAsBar', !showAsBar)}
                    />
                </Box>
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Typography className={'inter style-config-label'}>Apply to all</Typography>
                    <Switch checked={applyToAll} onClick={() => setRootPropValue('applyAll', !applyToAll)} />
                </Box>
                {!applyToAll ?
                    categories?.map((category, categoryIndex) => (
                        <Box sx={{ marginTop: '16px' }} key={categoryIndex}>
                            <Typography className={'inter style-config-label'}>{category}</Typography>
                            {
                                values?.map((value, valueIndex) => (
                                    <Box>
                                        <Row
                                            key={valueIndex}
                                            category={category}
                                            value={value}
                                            setEditingRow={setEditingRow}
                                            shouldExpand={shouldExpand}
                                            categories={categories}
                                            values={values}
                                            getSelectedImage={getSelectedImage}
                                            handleOpenSelect={handleOpenSelect}
                                        />
                                        {<Collapse in={shouldExpand(category, value)}>
                                            <Styles
                                                key={valueIndex}
                                                categoryIndex={categoryIndex}
                                                valueIndex={valueIndex}
                                                initialRotation={getPropValue('symbolRotate', categoryIndex, valueIndex)}
                                                initialSymbolSize={getPropValue('symbolSize', categoryIndex, valueIndex)}
                                                repeat={getPropValue('symbolRepeat', categoryIndex, valueIndex)}
                                                clip={getPropValue('symbolClip', categoryIndex, valueIndex)}
                                                handlePropChange={handlePropChange}
                                                handleDebouncedSetter={handleDebouncedSetter}
                                            />
                                        </Collapse>}
                                    </Box>
                                ))
                            }
                        </Box>
                    )) :
                    <Box sx={{ marginTop: '16px' }}>
                        <Row
                            category={'applyAll'}
                            value={'applyAll'}
                            setEditingRow={setEditingRow}
                            shouldExpand={shouldExpand}
                            categories={categories}
                            values={values}
                            getSelectedImage={getSelectedImage}
                            handleOpenSelect={handleOpenSelect}
                        />
                        <Styles
                            categoryIndex={-1}
                            valueIndex={-1}
                            initialRotation={getPropValue('symbolRotate', 0, 0)}
                            initialSymbolSize={getPropValue('symbolSize', 0, 0)}
                            repeat={getPropValue('symbolRepeat', 0, 0)}
                            clip={getPropValue('symbolClip', 0, 0)}
                            handlePropChange={handlePropChange}
                            handleDebouncedSetter={handleDebouncedSetter}
                        />
                    </Box>
                }
            </Box>
            <SelectImage
                images={uploadedImages}
                isOpen={isSelectDialogOpen}
                handleClose={() => setIsSelectDialogOpen(false)}
                handleSelectImage={handleSelectImage}
                refreshImages={getUploadedImages}
                index={values?.indexOf(selectedRow.current)}
            />
        </>
    )
}

const Row = ({
                category,
                value,
                setEditingRow,
                shouldExpand,
                categories,
                values,
                getSelectedImage,
                handleOpenSelect
}) => {

    const applyAll = category === 'applyAll' && value === 'applyAll';
    const valueIndex = values?.indexOf(value);
    const categoryIndex = categories?.indexOf(category);

    const iconStyle = {color: '#BDBDBD', cursor: 'pointer'}
    const isExpanded = shouldExpand(category, value)

    const handleIconClick = () => {
        if (applyAll) return;
        setEditingRow(isExpanded ? null : {category, value})
    }

    const imageSelected = applyAll ? getSelectedImage(0, 0) : getSelectedImage(categoryIndex, valueIndex)?.split('image://')[1];

    const imageName = imageSelected?.split('/')[imageSelected?.split('/').length - 1];

    return (
        <Box
            onClick={handleIconClick}
            className={'row'}
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 16px',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: '#f5f5f5',
                },
            }}
        >
            <Box className={'row'} sx={{gap: '8px'}}>
                <Typography sx={isExpanded} className={'inter style-config-label'}>{applyAll ? 'Select Image' : value}</Typography>
                <Typography sx={{
                    color: '#BDBDBD',
                    fontSize: '12px',
                    maxWidth: '100px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}
                            className={'inter'}
                >
                    {imageName}
                </Typography>
            </Box>
            <Box className={'row'} sx={{gap: '8px'}}>
                <IconButton>
                    <img
                        onClick={(e) => handleOpenSelect(e, category, value)}
                        src={imageSelected ? UploadRed : UploadGrey}
                        alt={'Open'}
                        style={{width: '20px', height:'20px', cursor: 'pointer'}}
                    />
                </IconButton>
                {applyAll ? null : !isExpanded ? <ExpandLessIcon sx={iconStyle}/> : <ExpandMoreIcon sx={iconStyle}/>}
            </Box>
        </Box>
    )
}

const Styles = ({
                    categoryIndex,
                    valueIndex,
                    initialRotation,
                    initialSymbolSize,
                    repeat,
                    clip,
                    handlePropChange,
                    handleDebouncedSetter
}) => {

    const [rotation, setRotation] = useState(initialRotation);
    const [symbolSize, setSymbolSize] = useState(initialSymbolSize);

    return (
        <Box className={'column'} sx={{
            gap: '16px',
            padding: '16px 16px 8px 24px',
            borderBottom: '1px solid #E6E4E6'
        }}>
            <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                <Typography className={'inter style-config-label'}>Repeat</Typography>
                <Switch checked={repeat}
                        onClick={() => handlePropChange(
                            'symbolRepeat',
                            !repeat,
                            categoryIndex,
                            valueIndex
                        )}
                />
            </Box>
            <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                <Typography className={'inter style-config-label'}>Clip image</Typography>
                <Switch checked={clip}
                        onClick={() => handlePropChange(
                            'symbolClip',
                            !clip,
                            categoryIndex,
                            valueIndex
                        )}
                />
            </Box>
            <Slider
                label={`Rotate: ${rotation}°`}
                value={rotation}
                onChange={(v) => handleDebouncedSetter('symbolRotate', v, setRotation, valueIndex, categoryIndex)}
                min={0}
                max={360}
                step={1}
            />
            <Slider
                label={`Width: ${symbolSize[0]}`}
                value={symbolSize[0].split('%')[0]}
                onChange={(v) => handleDebouncedSetter('symbolSize', [v + '%', symbolSize[1]], setSymbolSize, valueIndex, categoryIndex)}
                min={0}
                max={100}
                step={1}
            />
            <Slider
                label={`Height: ${symbolSize[1]}`}
                value={symbolSize[1].split('%')[0]}
                onChange={(v) => handleDebouncedSetter('symbolSize', [symbolSize[0], v + '%'], setSymbolSize, valueIndex, categoryIndex)}
                min={0}
                max={100}
                step={1}
            />
        </Box>
    )
}

const SelectImage = ({
                         images,
                         isOpen,
                         handleClose,
                         handleSelectImage,
                         refreshImages,
                     }) => {
    const [tabValue, setTabValue] = useState('select');
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        if (images.length > 0) {
            const transformedImages = transformImagesToTree(images);
            const firstCanvasName = Object.keys(transformedImages)[0];
            const firstGroupName = Object.keys(transformedImages[firstCanvasName])[0];

            setSelectedImages(transformedImages[firstCanvasName][firstGroupName]);
        }
    }, [isOpen, images]);

    const transformImagesToTree = (images) => {
        const tree = {};

        images.forEach((url) => {
            const urlParts = url.split('/');
            const canvasName = new URL(url).searchParams.get('canvasName') || 'Uploaded Images';
            let slideName = urlParts[urlParts.length - 2];
            slideName = slideName.replace(/Slide(\d+)/, 'Slide $1');

            if (!tree[canvasName]) tree[canvasName] = {};

            const groupName = slideName.startsWith('Slide') ? slideName : 'Others';

            if (!tree[canvasName][groupName]) tree[canvasName][groupName] = [];

            tree[canvasName][groupName].push(url);
        });
        return tree;
    };

    const renderTree = (canvasName, nodes, handleNodeSelect) => (
        Object.keys(nodes).map((key) => (
            <TreeItem
                key={key}
                nodeId={`${canvasName}-${key}`}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Folder} alt={'Folder'} style={{ marginRight: '8px' }} />
                        {key}
                    </Box>
                }
                onClick={() => handleNodeSelect(nodes[key])}
            >
                {!Array.isArray(nodes[key]) && renderTree(canvasName, nodes[key], handleNodeSelect)}
            </TreeItem>
        ))
    );

    const handleImageChange = (image) => {
        if (selectedImage === image) {
            setSelectedImage(null)
        } else {
            setSelectedImage(image)
        }
    }

    const handleNodeSelect = (images) => {
        if (Array.isArray(images)) setSelectedImages(images);
    };

    const dialogActions = (
        <Box display="flex" alignItems="flex-end" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} color="primary" text={'Cancel'}/>
            <Button onClick={() => handleSelectImage(selectedImage)} className={'primary-button button-purple'}
                    disabled={(!selectedImage && tabValue === 'select')} text={'Upload'}/>
        </Box>
    )

    const transformedImages = transformImagesToTree(images);

    return (
        <Dialog
            actions={tabValue === 'select' && dialogActions}
            style={{width: '1000px'}}
            maxWidth={false}
            title={'Select Image'}
            open={isOpen}
            onClose={handleClose}
        >
            <Box sx={{width: '50%'}}>
                <Tab
                    tabs={[
                        {key: 'select', label: 'Select'},
                        {key: 'upload', label: 'Upload'},
                    ]}
                    value={tabValue}
                    onChange={(event, newValue) => setTabValue(newValue)}
                />
            </Box>
            {
                tabValue === 'select' &&
                <Box sx={{ display: 'flex', height: '450px' }}>
                    <Box sx={{ width: '250px', overflowY: 'auto', borderRight: '1px solid #ccc', padding: '8px' }}>
                        <TreeView
                            sx={{
                                '.MuiTreeItem-iconContainer': {
                                    display: 'none',
                                },
                            }}
                        >
                            {Object.keys(transformedImages).map((canvasName => (
                                <TreeItem
                                    sx={{marginBottom: '8px'}}
                                    key={canvasName}
                                    nodeId={canvasName}
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <img style={{ marginRight: '8px' }}  alt={'folder'} src={Folder} />
                                            {canvasName}
                                        </Box>
                                    }
                                >
                                    {renderTree(canvasName, transformedImages[canvasName], handleNodeSelect)}
                                </TreeItem>
                            )))}
                        </TreeView>
                    </Box>
                    <Box sx={{ flex: 1, padding: '16px', display: 'flex', flexWrap: 'wrap', gap: '16px', overflowY: 'auto' }}>
                        {selectedImages.length > 0 ?
                            selectedImages.map((url, index) => (
                                <Box
                                    key={index}
                                    onClick={() => handleImageChange(url)}
                                    component="img"
                                    src={url}
                                    alt={`img-${index}`}
                                    width="120px"
                                    height="120px"
                                    sx={{
                                        border: selectedImage === url && '2px solid #E50B54',
                                        padding: '8px',
                                        transition: 'transform 0.3s, cursor 0.3s',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                            cursor: 'pointer',
                                            backgroundColor: 'rgba(229, 11, 84, 0.1)',
                                        },
                                    }}
                                /> )) : <Typography className={'inter'}>No images uploaded yet.</Typography>
                        }
                    </Box>
                </Box>
            }
            {tabValue === 'upload' && (
                <Box
                    className={'column'}
                    sx={{ gap: '16px', alignItems: 'center', width: '100%', flex: 1, height: '100%' }}
                >
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <UploadImageDialog
                            onUploadCallback={(uploadedImage) => {
                                refreshImages()
                                handleSelectImage(uploadedImage)
                            }}
                            open={true}
                            handleClose={handleClose} sx={{ flex: 1, width: '100%' }}
                        />
                    </Box>
                </Box>
            )}
        </Dialog>
    )
}