import Dialog from "../../../common/Dialog/Dialog";
import Input from "../../../common/Input/Input";
import {Box, Popover} from "@mui/material";
import Button from "../../../common/Button/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useApi} from "../../../../api/api";
import {useNavigate} from "react-router-dom";
import UploadPPT from '../../../Objects/UploadPPT';
import PlusRed from "../../../../assets/icons/PlusRed.svg";
import ShapesRed from "../../../../assets/icons/ShapesRed.svg";
import MicrosoftPowerPoint from "../../../../assets/icons/MicrosoftPowerpointLogo.svg";
import {ScoopLoader} from "../../../common/Spinner/ScoopLoader";
import {DialogItem} from "../../../common/DialogItem/DialogItem";
import {NewFromRecipe} from "../../Source/NewDatasetModal/NewFromRecipe";

export const NewCanvasDialog = ({anchorEl, onClose}) => {

    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const userID = useSelector((state) => state.auth.userID);
    const [canvasName, setCanvasName] = useState(null)
    const {postData: postCanvasData} = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/canvasV2');
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [showPPTUpload, setShowPPTUpload] = useState(false);
    const [showNewFromRecipe, setShowNewFromRecipe] = useState(false);
    const [showCreateCanvas, setShowCreateCanvas] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!Boolean(anchorEl)) setCanvasName(null)
    }, [Boolean(anchorEl)])

    const createCanvas = async () => {
        if (!userID || !workspaceID) return;
        setIsLoading(true)
        const action = {
            "action": "createCanvas",
            "userID": userID,
            "workspaceID": workspaceID,
            "canvasName": canvasName,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        }
        const result = await postCanvasData(action);
        if (result) {
            dispatch({
                type: 'APPLY_LOADED_OBJECTS_STATE',
                payload: [],
            });
            navigate(result.canvasID, {state: 'newCanvas'})
        }
        setIsLoading(false)
    }

    const createCanvasFromPPT = async () => {
        setShowPPTUpload(true);
    }

    const closePPTUpload = () => {
        setShowPPTUpload(false);
    }

    const actions = (
        <Box display="flex" justifyContent="space-between" width="100%">
            <Button onClick={() => {
                setShowCreateCanvas(false)
                onClose()
            }} className={'primary-button button-grey small'}
                    text={'Cancel'}></Button>
            <Button onClick={createCanvas} className={'primary-button button-purple small'}
                    disabled={!canvasName}>
                {isLoading ? <ScoopLoader size={26}/> : 'Create blank canvas'}
            </Button>

        </Box>

    )

    const renderCreateCanvasDialog = () => {
        return (
            <Dialog
                open={showCreateCanvas}
                onClose={() => setShowCreateCanvas(false)}
                title={'Create New Canvas'}
                actions={actions}
                fullWidth
            >
                <Input
                    padding={'8px'}
                    placeholder={'Enter Canvas Name'}
                    value={canvasName}
                    onChange={(event) => setCanvasName(event.target.value)}
                />
            </Dialog>
        )
    }

    const renderNewPopover = () => {
        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{marginTop: '8px', boxShadow: '0px 0px 7px 0px rgba(20, 9, 42, 0.25)'}}
            >
                <Box sx={{padding: '4px 0', borderRadius: '5px'}}>
                    <DialogItem description={'Create an empty canvas to design yourself'}
                                title={'Blank Canvas'}
                                icon={PlusRed}
                                onClick={() => setShowCreateCanvas(true)}
                    />
                    <DialogItem description={'Bring your Powerpoint slides into a new canvas'}
                                title={'Canvas from Presentation'}
                                onClick={createCanvasFromPPT}
                                icon={MicrosoftPowerPoint}
                    />
                    <DialogItem description={'Pre-made canvases for telling specific data stories'}
                                title={'Canvas from Recipe'}
                                icon={ShapesRed}
                                onClick={() => {
                                    setShowNewFromRecipe(true);
                                    onClose();
                                }}
                    />
                </Box>
            </Popover>
        )
    }


    return (
        <>
            {renderNewPopover()}
            {renderCreateCanvasDialog()}
            {showPPTUpload && (
                <UploadPPT isOpen={showPPTUpload} onClose={closePPTUpload} title={canvasName}/>
            )}
            {showNewFromRecipe && (
                <NewFromRecipe isOpen={showNewFromRecipe} setOpen={setShowNewFromRecipe}/>
            )}
        </>
    );

}
