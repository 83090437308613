import React, {useCallback, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {FONT_FAMILIES, FONT_WEIGHTS, KPI_DEFAULT_VALUES} from "../styleConsts";
import {Slider} from "../../../../../common/Slider/Slider";
import {propExistsOnObject, renderColorPicker, renderSelector} from "../utils";
import {debounce, cloneDeep} from "lodash";

export const KPIBodyConfig = ({kpiProp, config, setConfig, chartPreferences}) => {

    const [borderRadius, setBorderRadius] = useState(KPI_DEFAULT_VALUES.body.borderRadius);
    const [padding, setPadding] = useState(KPI_DEFAULT_VALUES.body.padding);

    useEffect(() => {
        setBorderRadius(getKPIPropValue('borderRadius'))
        setPadding(getKPIPropValue('padding'))
    }, [config])

    const debouncedFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.kpi.body[prop] = value
        setConfig(newConfig)
    }, 200), [config, kpiProp]);

    const getKPIPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.kpi.body, prop)) return config.styleOverrides.kpi.body[prop]
        if (chartPreferences.kpi && propExistsOnObject(chartPreferences.kpi.body, prop)) return chartPreferences.kpi.body[prop]
        return KPI_DEFAULT_VALUES.body[prop]
    }

    const handleDebounceKPIPropChange = (prop, value, setter) => {
        debouncedFunc(prop, value)
        setter(value)
    }

    return (
        <Box className={'column'} sx={{gap: '16px'}}>
            <Slider
                label={`Border radius: ${borderRadius}px`}
                value={borderRadius}
                min={0}
                max={50}
                step={1}
                onChange={(v) => handleDebounceKPIPropChange('borderRadius', v, setBorderRadius)}
            />
            <Slider
                label={`Margin: ${padding}px`}
                value={padding}
                min={0}
                max={50}
                step={1}
                onChange={(v) => handleDebounceKPIPropChange('padding', v, setPadding)}
            />
        </Box>
    )
}
