import {useState, useEffect, useRef} from 'react';
import {RESIZE_POSITION} from "../components/common/ElementWrapper/types";
import {useSelector} from "react-redux";

export const useResizeDnD = (
    delta,
    initialPosition,
    initialSize,
    updateElementPosition,
    updateElementSize,
    toggleEditMode,
    setWrapperSizeTrack,
    minSize = {width: 20, height: 20},
    snap,
    drag,
    editCanvas,
    multiSelect
) => {

    const  zoom = useSelector(state => state.ui.zoom);
    const [position, setPosition] = useState(initialPosition);
    const positionRef = useRef(initialPosition);
    const [resizePosition, setResizePosition] = useState(RESIZE_POSITION.BOTTOM_RIGHT);
    const [size, setSize] = useState(initialSize);
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const [mouseDownPosition, setMouseDownPosition] = useState({x: 0, y: 0})
    const [mouseDown, setMouseDown] = useState(false)
    const [dragOffset, setDragOffset] = useState({x: 0, y: 0});
    const [resizeOffset, setResizeOffset] = useState({x: 0, y: 0});

    useEffect(() => {
        if (multiSelect) setPosition(initialPosition)
    }, [initialPosition, isDragging])

    useEffect(() => {
        setSize(initialSize)
    }, [initialSize]);

    const resetDragAndResize = () => {
        setIsDragging(false);
        setIsResizing(false);
        setMouseDown(false);
    }

    const handleClick = () => {
        toggleEditMode(true);
    }

    const handleMouseDown = (e) => {
        if (!!e.target.getAttribute('class')?.includes('MuiInputBase') || e.button === 2) return
        setMouseDown(true)
        setMouseDownPosition({x: e.pageX, y: e.pageY})
        const offsetX = e.clientX/zoom - position.x;
        const offsetY = e.clientY/zoom - position.y;
        setDragOffset({x: offsetX, y: offsetY});
    };

    const handleResizeStart = (e, resizePosition) => {
        setResizePosition(resizePosition)
        setIsResizing(true)
        setMouseDown(true)
        let offsetX = 0;
        let offsetY = 0;
        switch (resizePosition) {
            case RESIZE_POSITION.BOTTOM_RIGHT:
                offsetX = e.clientX/zoom - size.width;
                offsetY = e.clientY/zoom - size.height;
                break;
            case RESIZE_POSITION.BOTTOM_LEFT:
                offsetX = e.clientX/zoom;
                offsetY = e.clientY/zoom - size.height;
                break;
            case RESIZE_POSITION.TOP_LEFT:
                offsetX = e.clientX/zoom;
                offsetY = e.clientY/zoom;
                break;
            case RESIZE_POSITION.TOP_RIGHT:
                offsetX = e.clientX/zoom - size.width;
                offsetY = e.clientY/zoom;
                break;
        }
        setResizeOffset({x: offsetX, y: offsetY});
    };

    const handleMouseUp = () => {
        if (updateElementPosition && (isDragging || isResizing)) updateElementPosition(positionRef.current);
        if (updateElementSize && (isDragging || isResizing)) {
            if (setWrapperSizeTrack) setWrapperSizeTrack(size);
            updateElementSize(size);
        }
        resetDragAndResize();
    };

    const handleMove = (e) => {
        if (!isDragging && mouseDown) {
            const diffX = Math.abs(e.pageX - mouseDownPosition.x);
            const diffY = Math.abs(e.pageY - mouseDownPosition.y);
            if (diffX >= delta || diffY >= delta) setIsDragging(true);
        }
        if (isResizing && mouseDown) {
            switch (resizePosition) {
                case RESIZE_POSITION.BOTTOM_RIGHT:
                    let brWidth = Math.max(e.clientX/zoom - resizeOffset.x, minSize.width)
                    let brHeight = Math.max(e.clientY/zoom - resizeOffset.y, minSize.height)
                    if (snap) {
                        brWidth = Math.round(brWidth / 20) * 20
                        brHeight = Math.round(brHeight / 20) * 20
                    }
                    setSize({width: brWidth, height: brHeight});
                    break;
                case RESIZE_POSITION.BOTTOM_LEFT:
                    let blWidth = Math.max(size.width - (e.clientX/zoom - resizeOffset.x), minSize.width)
                    let blHeight = Math.max(e.clientY/zoom - resizeOffset.y, minSize.height)
                    let blx = position.x + (e.clientX/zoom - resizeOffset.x)
                    if (snap) {
                        blWidth = Math.round(blWidth / 20) * 20
                        blHeight = Math.round(blHeight / 20) * 20
                        blx = Math.round(blx / 20) * 20
                    }
                    setSize({width: blWidth, height: blHeight});
                    if (blWidth > minSize.width) setPosition(pos => ({...pos, x: blx}))
                    break;
                case RESIZE_POSITION.TOP_LEFT:
                    let tlWidth = Math.max(size.width - (e.clientX/zoom - resizeOffset.x), minSize.width)
                    let tlHeight = Math.max(size.height - (e.clientY/zoom - resizeOffset.y), minSize.height)
                    let tlx = position.x + (e.clientX/zoom - resizeOffset.x)
                    let tly = position.y + (e.clientY/zoom - resizeOffset.y)
                    if (snap) {
                        tlWidth = Math.round(tlWidth / 20) * 20
                        tlHeight = Math.round(tlHeight / 20) * 20
                        tlx = Math.round(tlx / 20) * 20
                        tly = Math.round(tly / 20) * 20
                    }
                    setSize({width: tlWidth, height: tlHeight});
                    if (tlWidth > minSize.width) setPosition(pos => ({...pos, x: tlx}));
                    if (tlHeight > minSize.height) setPosition(pos => ({...pos, y: tly}));
                    break;
                case RESIZE_POSITION.TOP_RIGHT:
                    let trWidth = Math.max(e.clientX/zoom - resizeOffset.x, minSize.width)
                    let trHeight = Math.max(size.height - (e.clientY/zoom - resizeOffset.y), minSize.height)
                    let tRy = position.y + (e.clientY/zoom - resizeOffset.y)
                    if (snap) {
                        trWidth = Math.round(trWidth / 20) * 20
                        trHeight = Math.round(trHeight / 20) * 20
                        tRy = Math.round(tRy / 20) * 20
                    }
                    setSize({width: trWidth, height: trHeight});
                    if (trHeight > minSize.height) setPosition(pos => ({...pos, y: tRy}))
                    break;
            }
        } else if (isDragging && mouseDown) {
            if (drag === false) return;
            if (snap) {
                setPosition({
                    x: Math.round((e.clientX/zoom - dragOffset.x) / 20) * 20,
                    y: Math.round((e.clientY/zoom - dragOffset.y) / 20) * 20
                });
            } else {
                setPosition({
                    x: e.clientX/zoom - dragOffset.x,
                    y: e.clientY/zoom - dragOffset.y
                });
            }
        }
    };

    useEffect(() => {
        positionRef.current = position;
    }, [position]);

    useEffect(() => {
        if (editCanvas && (isDragging || isResizing)) {
            document.addEventListener('mousemove', handleMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, isResizing, editCanvas]);

    return {
        position,
        size,
        handleMouseDown,
        handleResizeStart,
        handleMove,
        handleMouseUp,
        handleClick,
        resetDragAndResize
    }
};
