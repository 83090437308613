import Button from "../../../common/Button/Button";
import {Box, Select, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Input from "../../../common/Input/Input";
import {DataGrid} from "@mui/x-data-grid";
import Dialog from "../../../common/Dialog/Dialog";
import React from "react";

export const APIFilterField = ({
                                   APIFeatures,
                                   filterMap,
                                   filterModalOpen,
                                   setFilterModalOpen,
                                   onFilterModalClose,
                                   filterField,
                                   setFilterField,
                                   operator,
                                   setOperator
                               }) => {
    const [value, setValue] = React.useState(null)
    const [values, setValues] = React.useState([])

    return <Dialog open={filterModalOpen} onClose={onFilterModalClose}
                   actions={<>
                       <Button className={'button-grey small'} onClick={() => {
                           setFilterModalOpen(false);
                           setFilterField(null);
                           setValues([]);
                       }}>Cancel</Button>
                       <Button className={'button-purple small'}
                               disabled={(!values || values.length === 0) && filterField.fieldType !== "DateTime" && !value}
                               onClick={() => {
                                   if (operator === "In" || operator === "NotIn") {
                                       let newValues = [];
                                       for (let i = 0; i < values.length; i++) {
                                           newValues.push(values[i].value);
                                       }
                                       filterMap.set(filterField.name, {
                                           operator: operator, values: newValues
                                       });
                                   } else {
                                       filterMap.set(filterField.name, {
                                           operator: operator, values: [value]
                                       });
                                   }
                                   setFilterModalOpen(false);
                                   setValues([]);
                               }}>Set Filter</Button>
                   </>}>
        <Typography>Filter on field {filterField.label}</Typography>
        <Box sx={{width: 500, height: 50, display: 'flex'}}>
            <Select sx={{height: 32, mr: 2}} value={operator} onChange={event => {
                setOperator(event.target.value);
            }}>
                {filterField.fieldType !== "DateTime" && APIFeatures.supportedFilterOperators.includes('Equals') &&
                    <MenuItem value={"Equals"}>Equals</MenuItem>}
                {filterField.fieldType !== "DateTime" && APIFeatures.supportedFilterOperators.includes('NotEquals') &&
                    <MenuItem value={"NotEquals"}>Not Equals</MenuItem>}
                {filterField.fieldType !== "DateTime" && APIFeatures.supportedFilterOperators.includes('In') &&
                    <MenuItem value={"In"}>In</MenuItem>}
                {filterField.fieldType !== "DateTime" && APIFeatures.supportedFilterOperators.includes('NotIn') &&
                    <MenuItem value={"NotIn"}>Not In</MenuItem>}
                {filterField.fieldType === "DateTime" && <MenuItem value={"LastDay"}>Last Day</MenuItem>}
                {filterField.fieldType === "DateTime" && <MenuItem value={"LastWeek"}>Last Week</MenuItem>}
                {filterField.fieldType === "DateTime" && <MenuItem value={"LastMonth"}>Last Month</MenuItem>}
                {filterField.fieldType === "DateTime" && <MenuItem value={"LastYear"}>Last Year</MenuItem>}
            </Select>
            {filterField.fieldType !== "DateTime" && <Input sx={{width: 300}} onChange={(event) => {
                setValue(event.target.value);
            }}></Input>}{(operator === "In" || operator === "NotIn") &&
            <Button className={'button-purple small'} sx={{height: 32, ml: 2}}
                    onClick={(event) => {
                        let newValues = [];
                        let id = 0;
                        for (let i = 0; i < values.length; i++) {
                            newValues.push({id: i, value: values[i].value});
                        }
                        newValues.push({id: values.length, value: value});
                        setValues(newValues);
                    }}>Add</Button>}
        </Box>
        {(operator === "In" || operator === "NotIn") && values && <DataGrid autoHeight columns={[{
            field: "value",
            headerName: "Value",
            type: "string",
            sortable: false,
            editable: false,
            width: '100%',
            headerAlign: 'center',
        }]}
                                                                            initialState={{
                                                                                pagination: {
                                                                                    paginationModel: {
                                                                                        page: 0, pageSize: 10
                                                                                    },
                                                                                },
                                                                            }}
                                                                            rows={values}
                                                                            rowHeight={25}
                                                                            onRowSelectionModelChange={(ids) => {
                                                                            }}
                                                                            sx={{
                                                                                '& .MuiDataGrid-columnHeaders': {
                                                                                    display: 'none',
                                                                                },
                                                                            }}
        />}
    </Dialog>
}