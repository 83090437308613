import * as React from "react";
import {Box, Checkbox, MenuItem, Select, Typography, ListItem, Menu, checkboxClasses} from "@mui/material";
import * as PropTypes from "prop-types";
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import {createRef} from "react";
import PencilSimple from "../../assets/icons/PencilSimple.svg";
import Trash from "../../assets/icons/Trash.svg";
import Copy from "../../assets/icons/Copy.svg";
import IconButton from "@mui/material/IconButton";
import { cloneDeep } from "lodash";

export class ColumnTreeItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, hover: false
        };
        this.anchorRef = createRef();
    }

    isSelected(reportSeriesTableID, columnName, kpi, newItems, config) {
        if (config.view === 'table') {
            let selected = false
            config.selectedTableKpis.forEach(k => {
                if (k.metricName === kpi) selected = true;
            })
            return selected
        } else {
            for (var i = 0; i < config.selectedItems.length; i++) {
                if (kpi) {
                    if (config.selectedItems[i].reportSeriesTableID === reportSeriesTableID && config.selectedItems[i].kpi === kpi) return true;
                } else {
                    if (config.selectedItems[i].reportSeriesTableID === reportSeriesTableID && config.selectedItems[i].columnName === columnName) return true;
                }
            }
        }
        return false;
    }

    handleMenuOpen = (event) => {
        this.setState({
            open: true,
        });
    };

    handleMenuClose = () => {
        this.setState({
            open: false,
        });
    };

    handleMouseEnter = () => {
        this.setState({
            hover: true,
        });
    }

    handleMouseLeave = () => {
        this.setState({
            hover: false,
        });
    }

    getKpiName = (metricName) => {
        const { workspaceMetadata } = this.props;
        const kpiNames = workspaceMetadata.kpis.map(kpi => kpi.metricName);
        let i = 2;
        while (kpiNames.includes(metricName + ` (${i})`)) i++;
        return metricName + ` (${i})`;
    }

    handleCloneKpi = () => {
        const { server, kpi, config, chartState, getWorkspaceMetadata } = this.props;
        let newKpi = cloneDeep(kpi);
        delete newKpi.metricKey;
        newKpi.metricName = this.getKpiName(newKpi.metricName);
        server.postData({
            "action": "putObject", "class": "scoop.metric.Metric", "value": newKpi
        }, () => {
            this.handleMenuClose();
            getWorkspaceMetadata();
        } );
    }

    render() {

        let {
            setIsNew,
            table,
            column,
            kpi,
            config,
            setConfig,
            setSelectedColumn,
            setSelectedColumnDate,
            setSelectedMetric,
            setDeleteKpi,
            noEdit,
            newItems
        } = this.props;
        if (!kpi && (!column || !column.isMeasure)) return;
        if (!config.selectedItems) return;
        const selected = this.isSelected(kpi ? kpi.reportSeriesTableID : table.reportSeriesTableID, column?.columnName, kpi?.metricName, newItems, config);
        let selectedDate;
        let selDate;
        let snapshotDate;
        if (selected && !kpi) {
            selectedDate = config.selectedDates ? config.selectedDates.get(table.reportSeriesTableID + ":" + column.columnName) : undefined;
            snapshotDate = JSON.stringify({
                selectedDate: "Snapshot Date", reportSeriesTableID: table.reportSeriesTableID, columnName: column.columnName
            });
            if (!selectedDate) {
                selDate = snapshotDate;
            } else {
                selDate = JSON.stringify({
                    selectedDate: selectedDate, reportSeriesTableID: table.reportSeriesTableID, columnName: column.columnName
                });
            }
        }

        return (
            <ListItem
                sx={{
                    padding: '5px 5px 5px 8px',
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: this.state.open && !noEdit ? '#F9F9F9' : 'inherit',
                    ':hover': {
                        backgroundColor: '#F9F9F9',
                    },
                }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <Box
                    className="menu-tree-item"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '25px',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: noEdit ? '-webkit-fill-available' : '85%'
                        }}
                    >
                        <Checkbox
                            sx={{
                                height: 10,
                                width: 10,
                                '&.MuiCheckbox-root': {
                                    color: '#C7C5C7'
                                },
                                [`&, &.${checkboxClasses.checked}`]: {
                                    color: '#E50B54',
                                },
                            }}
                            value={JSON.stringify({
                                reportSeriesTableID: kpi ? kpi.reportSeriesTableID : table.reportSeriesTableID,
                                columnName: column ? column.columnName : undefined,
                                kpi: kpi ? kpi.metricName : undefined
                            })}
                            checked={selected}
                            onClick={setSelectedColumn}
                            size="medium"
                        />
                        <Typography
                            className={'inter'}
                            sx={{
                                marginLeft: '10px',
                                fontSize: 14,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontWeight: 400,
                                maxWidth: noEdit ? 130 : 200
                            }}
                            noWrap
                        >
                            {kpi ? kpi.metricName : column.columnName}
                        </Typography>
                    </Box>
                    {
                        (noEdit ? (selected && !kpi && table.dates) : (this.state.hover || this.state.open)) &&
                        <IconButton
                            ref={this.anchorRef}
                            onClick={(e) => {noEdit ? this.handleMenuOpen(e) : this.handleMenuOpen(e)}}
                            sx={{padding: '4px', borderRadius: '5px', border: '1px solid #E6E4E6', color: 'inherit'}}
                        >
                            <MoreHorizRoundedIcon fontSize={'small'}/>
                        </IconButton>
                    }
                </Box>
                <Menu
                    open={this.state.open}
                    anchorEl={this.anchorRef?.current}
                    onClose={() => this.handleMenuClose()}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'left'}}
                    sx={{fontSize: '14px', width: '520px', transform: 'translateX(30px)'}}
                >
                    {
                        noEdit && table.dates ?
                        [
                            <MenuItem
                                key={'snapshot-key'}
                                value={snapshotDate}
                                sx={{backgroundColor: selDate === snapshotDate ? '#dad9d9' : ''}}
                                onClick={() => {
                                    setSelectedColumnDate(snapshotDate)
                                    this.handleMenuClose()
                                }}
                            >
                                by Snapshot Date
                            </MenuItem>,
                            ...table.dates.map((d) => {
                                const value = JSON.stringify({
                                    selectedDate: d,
                                    reportSeriesTableID: table.reportSeriesTableID,
                                    columnName: column.columnName
                                })
                                return (
                                    <MenuItem
                                        key={d}
                                        value={value}
                                        sx={{backgroundColor: selDate === value ? '#dad9d9' : ''}}
                                        onClick={() => {
                                            setSelectedColumnDate(value)
                                            this.handleMenuClose()
                                        }}
                                    >
                                        by {d}
                                    </MenuItem>
                                )
                            })
                        ] :
                        [
                            <MenuItem
                                key={'edit-key'}
                                onClick={() => {
                                    setIsNew(false);
                                    setSelectedMetric({...kpi});
                                    this.handleMenuClose()
                                }}
                                sx={{fontSize: '14px', width: '140px'}}
                            >
                                <img style={{paddingRight: 10}} src={PencilSimple} alt={'Edit'} />
                                {'Edit'}
                            </MenuItem>,
                            <MenuItem
                                key={'clone-key'}
                                onClick={() => this.handleCloneKpi()}
                                sx={{fontSize: '14px', width: '140px'}}
                            >
                                <img style={{paddingRight: 10}} src={Copy} alt={'Clone'} />
                                {'Clone'}
                            </MenuItem>,
                            <MenuItem
                                key={'delete-key'}
                                onClick={() => {
                                    setIsNew(false);
                                    setSelectedMetric({...kpi});
                                    setDeleteKpi(true);
                                    this.handleMenuClose()
                                }}
                                sx={{fontSize: '14px', width: '140px'}}
                            >
                                <img style={{paddingRight: 10}} src={Trash} alt={'Delete'} />
                                {'Delete'}
                            </MenuItem>
                        ]
                    }
                </Menu>
            </ListItem>
        );
    }
}

ColumnTreeItem.propTypes = {
    table: PropTypes.any, column: PropTypes.any, kpi: PropTypes.any
}
