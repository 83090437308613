// TextEditorElement.js
import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';
// Import the PNG file
import {useDispatch, useSelector} from 'react-redux';
import {updateInsightPosition, updateInsightSize, updateTextEditor} from '../../../store/actions';
import {ElementWrapper} from "../../common/ElementWrapper/ElementWrapper";
import {debounce} from "lodash";
import {useApi} from '../../../api/api';
// Froala Editor
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
//Import all Froala Editor plugins;
import "froala-editor/js/plugins.pkgd.min.js";
import {Box} from "@mui/material";
import {Server} from "../../../api/Server";

const TextEditorElement = ({
                               id,
                               initialPosition,
                               initialSize,
                               value,
                               replacement,
                               worksheetID,
                               rangeName,
                               fitData,
                               multiProps,
                               setShowMenu,
                               canvasID,
                               slides
                           }) => {

    const {postData: postDataWorkspaceactions} = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/workspaceactions');

    const textEditorID = `TextEditorElement-${id}`
    const userID = useSelector(state => state.auth.userID);
    const token = useSelector(state => state.auth.token);
    const workspaceID = useSelector(state => state.auth.workspaceID);
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token));

    const dispatch = useDispatch();
    const activeMode = useSelector(state => state.ui.activeMode);
    const [editMode, setEditMode] = useState(false);
    const [editText, setEditText] = useState(false);
    const [content, setContent] = useState(value);
    const [rawContent, setRawContent] = useState(value);
    const parentRef = useRef(null);
    const [size, setSize] = useState(initialSize);

    const isPastedContent = useRef(false); // Use useRef to track paste events
    const debouncedSave = useCallback(debounce((value) => {
        dispatch(updateTextEditor(id, value));
    }, 1000), []);

    useEffect(() => {
        setContent(value);
        setRawContent(value);
    }, [value]);

    const handleModelChange = (event) => {
        setContent(event);
        setRawContent(event);
        debouncedSave(event);
    };

    // when user uploads an image to S3, make sure the filename is safe
    function safeS3FileName(str) {
        return str.replace(/[^a-zA-Z0-9._-]/g, '_').replace(/ /g, '_');
    }

    const buildPredefinedLinks = () => {
        let predefinedLinks = [];
        slides.forEach((object, index) => {
            predefinedLinks.push({
                text: "Slide " + (index + 1),
                href: `/canvas/${canvasID}/presentation/${index}`
            });
        })
        return predefinedLinks;
    }

    const predefinedLinks = useMemo(() => buildPredefinedLinks(), [canvasID, slides]);

    let config = {
    key: "bMA6aC5E6F3D2G2B2I2yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5D4C4E3E2D4F2H1==",
    charCounterCount: false,
    quickInsertEnabled: false,
    toolbarVisibleWithoutSelection: true,
    toolbarContainer: '#textEditor-menu',
    toolbarBottom: true,
    placeholderText: '',
    linkList: predefinedLinks,
    linkValidation: true,
    toolbarButtons: {
        'moreText': {'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']},
        'moreParagraph': {'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']},
        'moreRich': {'buttons': ['insertLink', 'insertImage', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'insertHR']},
        'moreMisc': {'buttons': ['undo', 'redo', 'spellChecker', 'selectAll']}
    },
    events: {
        'paste.beforeCleanup': function (clipboard_html) {
            const div = document.createElement('div');
            div.innerHTML = clipboard_html;
            const images = div.querySelectorAll('img');
            images.forEach(img => {
                const src = img.getAttribute('src');
                // Check if the src is a blob or a relative path
                if (src.startsWith('blob:') || !src.startsWith('http')) {
                    // Remove the image or replace with a placeholder
                    // For example, to remove the image:
                    img.parentNode.removeChild(img);
                    // Or to replace with a placeholder image:
                    // img.setAttribute('src', 'path/to/your/placeholder/image.jpg');
                }
            });
            return div.innerHTML; // Return the cleaned HTML
        },
        'paste.after': function () {
            isPastedContent.current = true;
            setTimeout(() => {
                // Attempt to close the Froala "Uploading" modal if it's open
                // This is a bit of a hack and relies on Froala's internal modal structure
                const uploadModal = this.popups.get('image.insert');
                if (uploadModal) {
                    this.popups.hide('image.insert');
                }
            }, 100); // Delay just enough for the modal to potentially open
        },
        'image.beforeUpload': function (images) {
            if (isPastedContent.current) {
                // Reset flag
                isPastedContent.current = false;
                // Skip custom upload for pasted images to maintain their original URLs
                return false; // This prevents the upload to S3 for pasted images
            }

            // Custom upload logic for dropped images
            if (images.length > 0) {
                const imageFile = images[0];
                let payload = {
                    "action": "uploadImage",
                    "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
                };
                postDataWorkspaceactions({
                    ...payload,
                    fileName: safeS3FileName(imageFile.name),
                    fileType: imageFile.type
                }).then(presignedData => {
                    fetch(presignedData.signedRequest, {
                        method: 'PUT',
                        body: imageFile,
                        headers: {
                            'Content-Type': imageFile.type
                        }
                    }).then(response => {
                        if (response.ok) {
                            this.image.insert(presignedData.url, true, null, this.image.get(), imageFile);
                        } else {
                            console.error('Upload failed:', response);
                        }
                    }).catch(error => {
                        console.error('Error uploading image:', error);
                    });
                }).catch(error => {
                    console.error('Error getting presigned URL:', error);
                });

                return false; // Prevent Froala's default upload process for dropped images
            }
        }
    },
        keepFormatOnDelete: true
    };

    // Initialize Froala config state
    const [froalaConfig, setFroalaConfig] = useState({
        ...config, // Spread the initial config
        // You can omit the height here as it will be set in useEffect below
    });

    // Update Froala config whenever the size changes
    useEffect(() => {
        setFroalaConfig(prevConfig => ({
            ...prevConfig,
            height: size.height * 0.8, // Adjust based on your needs
            // Any other dynamic config changes based on size
        }));
    }, [size]); // Depend on size

    const updateInsightPos = (position) => {
        dispatch(updateInsightPosition(id, position))
    }

    const updateInsightSz = (size) => {
        dispatch(updateInsightSize(id, size))
    }

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                })
            }
        });
        const parentElement = parentRef.current;
        if (parentElement) {
            observer.observe(parentElement);
        }
        return () => {
            observer.disconnect();
        };
    }, [editMode]);

    const toggleEditMode = (value) => {
        if (value) {
            setShowMenu(true)
            setContent(rawContent);
        } else {
            setShowMenu(false)
            setEditText(false)
            let cellAddresses = getCellAddresses(rawContent);
            renderContent(cellAddresses)
        }
        setEditMode(value)
    }

    function fetchCellValues(cellAddresses) {
        return new Promise((resolve) => {
            try {
                server.sheetPostData({
                    "action": "getCells",
                    "addresses": cellAddresses,
                }, (result) => {
                    let cellValueMap = new Map();
                    let searchRangeValueMap = new Map();
                    let sheetRangeValueMap = new Map();
                    for (let i = 0; i < result.values.length; i++) {
                        if (result.values[i].cell) {
                            cellValueMap.set(result.values[i].cell, result.values[i].value);
                        } else if (result.values[i].searchRange) {
                            searchRangeValueMap.set(result.values[i].searchRange, result.values[i].rows);
                        } else if (result.values[i].worksheetID) {
                            sheetRangeValueMap.set(result.values[i].worksheetID + ":" + result.values[i].rangeName, result.values[i].rows)
                        }
                    }
                    resolve({
                        cellMap: cellValueMap,
                        searchRangeMap: searchRangeValueMap,
                        sheetRangeMap: sheetRangeValueMap
                    });
                });
            } catch (e) {
                console.error(e)
            }
        });
    }


    const cellRegex = /{CELL\((((&#39;)|'|‘)?([A-Za-z\[\]]|&nbsp;| | |(<\/span><span style="font-size:([a-zA-Z.:;-]|\d|,|\()+\);">))+((&#39;)|'|’)?[!]+[A-Za-z]{1,2}[0-9]{1,4})\)}/g;
    const rangeRegex = /{RANGE\((((&#39;)|'|‘)?([A-Za-z\[\]]|&nbsp;| | |(<\/span><span style="font-size:([a-zA-Z.:;-]|\d|,|\()+\);">))+((&#39;)|'|’)?)\)}/g;

    useEffect(() => {
        if (replacement) {
            let cellAddresses = getCellAddresses(rawContent);
            if (cellAddresses.length === 0) {
                setContent(rawContent);
            } else {
                setContent("&nbsp;");
                renderContent(cellAddresses)
            }
        }
    }, [worksheetID, replacement])

    function getCellAddresses(rawContent) {
        let match;
        let cellAddresses = [];
        if (worksheetID) {
            cellAddresses.push({
                type: 'range',
                worksheetID: worksheetID,
                rangeName: rangeName
            })
        }
        // Look for the inline cell references. Collect all unique cell addresses
        while ((match = cellRegex.exec(rawContent)) !== null) {
            cellAddresses.push({
                type: 'cell',
                address: match[1]
            });
        }
        while ((match = rangeRegex.exec(rawContent)) !== null) {
            cellAddresses.push({
                type: 'range',
                address: match[1]
            });
        }
        return cellAddresses;
    }

    async function renderContent(cellAddresses) {
        // If no cell addresses are found, do nothing
        if (cellAddresses.length !== 0) {
            // Fetch cell values from the server
            const cellValues = await fetchCellValues(cellAddresses);
            let replaced = rawContent;
            if (cellValues.cellMap.size > 0) {
                // Replace all occurrences in the string with their corresponding values
                replaced = replaced.replace(cellRegex, (match, p1) => cellValues.cellMap.get(p1) || match);
            }
            if (cellValues.searchRangeMap.size > 0) {
                // Replace all occurrences in the string with their corresponding values
                let match = rangeRegex.exec(replaced);
                if (match != null && cellValues.searchRangeMap.get(match[1]) != null) {
                    replaced = getReplacedDom(replaced, cellValues.searchRangeMap.get(match[1]));
                }
                rangeRegex.lastIndex = 0;
            }
            if (cellValues.sheetRangeMap.size > 0) {
                replaced = getReplacedDom(replaced, cellValues.sheetRangeMap.values().next().value);
            }
            setContent(replaced)
        }
    }

    function getReplacedDom(replaced, replacement) {
        const parser = new DOMParser();
        let dom = parser.parseFromString(replaced, "text/html");
        let table = dom.getElementsByTagName("table")[0];
        if (table) {
            replaceTableDom(table, replacement, fitData);
            replaced = dom.documentElement.children[1].innerHTML;
        }
        return replaced;
    }

    function replaceTableDom(table, data, fitData) {
        if (!table || !data)
            return;
        if (fitData) {
            if (table.rows[0].cells.length < data[0].length) {
                // Add new columns
                for (let row = 0; row < table.rows.length && row < data.length; row++) {
                    for (let col = table.rows[row].cells.length; col < data[row].length; col++) {
                        let newCell = table.rows[row].cells[table.rows[row].cells.length - 1].cloneNode(true);
                        table.rows[row].append(newCell);
                    }
                }
            } else if (table.rows[0].cells.length > data[0].length) {
                // Trim excess columns
                for (let row = 0; row < table.rows.length && row < data.length; row++) {
                    let rowLen = table.rows[row].cells.length;
                    for (let col = data[row].length; col < rowLen; col++) {
                        table.rows[row].deleteCell(-1);
                    }
                }
            }
            if (table.rows.length < data.length) {
                // Add new rows
                for (let row = table.rows.length; row < data.length; row++) {
                    let newRow = table.rows[table.rows.length - 1].cloneNode(true);
                    table.append(newRow);
                }
            } else if (table.rows.length > data.length) {
                // Trim excess rows
                let numRows = table.rows.length;
                for (let row = data.length; row < numRows; row++) {
                    table.deleteRow(-1);
                }
            }
        }
        for (let row = 0; row < table.rows.length && row < data.length; row++) {
            for (let col = 0; col < table.rows[row].cells.length && col < data[row].length; col++) {
                let cell = table.rows[row].cells[col];
                setCell(cell, data[row][col])
            }
            if (fitData && table.rows[row].cells.length < data[row].length) {
                for (let col = table.rows[row].cells.length; col < data[row].length; col++) {
                    let newCell = table.rows[row].cells[table.rows[row].cells.length - 1].cloneNode(true);
                    table.rows[row].append(newCell);
                    setCell(newCell, data[row][col])
                }
            }
        }
    }

    function setCell(cell, value) {
        let paragraph = cell.children[0].children[0];
        if (paragraph.children[0]) {
            paragraph.children[0].innerHTML = value;
            while (paragraph.children[1]) {
                paragraph.removeChild(paragraph.children[1])
            }
        } else {
            paragraph.innerHTML = value;
        }
    }

    return (
        <ElementWrapper
            id={textEditorID}
            key={textEditorID}
            type={'TextEditor'}
            editMode={editMode}
            toggleEditMode={toggleEditMode}
            initialPosition={initialPosition}
            initialSize={size}
            updateElementPosition={updateInsightPos}
            updateElementSize={updateInsightSz}
            {...multiProps}
            sizeRef={parentRef}
            minSize={{width: 100, height: 50}}
            drag={!editText}
        >
            {
                editText ?
                    <Box sx={{width: '100%', height: '100%'}}>
                        <FroalaEditorComponent
                            tag={'textarea'}
                            model={content}
                            config={froalaConfig}
                            onModelChange={handleModelChange}
                        />
                    </Box> :
                    <Box
                        onDoubleClick={() => activeMode === 'edit' && setEditText(true)}
                        sx={{width: '100%', height: '100%'}}
                        overflow={'hidden'}
                        className={activeMode === 'edit' ? 'disabled-links' : ''}
                    >
                        <FroalaEditorView
                            model={(content === "<p><br></p>" || content === "") ? " Start typing here..." : content}/>
                    </Box>
            }
        </ElementWrapper>
    );
};

export default TextEditorElement;
