import React, {useState} from "react";
import './StyleTab.css';
import {CONFIG_BUTTONS, CONFIG_OPTIONS, DEFAULT_CHART_PREFERENCES} from "./styleConsts";
import {Box, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import CaretRight from '../../../../../assets/icons/CaretRight.svg';
import CaretLeft from '../../../../../assets/icons/CaretLeft.svg';
import {ColorConfig} from "./config/ColorConfig";
import {TitleConfig} from "./config/TitleConfig";
import {LegendConfig} from "./config/LegendConfig";
import {SplitLineConfig} from "./config/SplitLineConfig";
import {TooltipConfig} from "./config/TooltipConfig";
import {AxisConfig} from "./config/AxisConfig";
import Button from "../../../../common/Button/Button";
import {ThemeItem} from "./ThemeItem/ThemeItem";
import {ThemesConfig} from "./config/ThemesConfig";
import {CreateThemeDialog} from "./CreateThemeDialog/CreateThemeDialog";
import _ from 'lodash';
import {BarConfig} from "./config/BarConfig";
import {LineConfig} from "./config/LineConfig";
import {MarginConfig} from "./config/MarginConfig";
import {WaterfallConfig} from "./config/WaterfallConfig";
import {ScoopLoader} from "../../../../common/Spinner/ScoopLoader";
import {getDefaultChartPreferences} from "./utils";
import {PieConfig} from "./config/PieConfig";
import {PictorialConfig} from "./config/PictorialConfig";
import {GaugeConfig} from "./config/GaugeConfig";
import {RadialConfig} from "./config/RadialConfig";
import {TableConfig} from "./config/TableConfig";
import {KPITextConfig} from "./config/KPITextConfig";
import {KPIBodyConfig} from "./config/KPIBodyConfig";

export const StyleTab = ({
                             config,
                             setConfig,
                             chartState,
                             workspaceMetadata,
                             server,
                             getWorkspaceMetadata,
                             handleSaveInsight
                         }) => {

    const [selectedProp, setSelectedProp] = useState(CONFIG_OPTIONS.MAIN);
    const [previousTheme, setPreviousTheme] = useState(undefined);
    const [previousStyleOverrides, setPreviousStyleOverrides] = useState(undefined);
    const [createThemeOpen, setCreateThemeOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const renderConfigOption = ({title, children, onBack}) => {
        return (
            <Box className={'config-container'}>
                <Box className={'tab-header'}>
                    <IconButton onClick={() => {
                        setSelectedProp(CONFIG_OPTIONS.MAIN)
                        onBack && onBack()
                    }}>
                        <img src={CaretLeft} alt={'caret-left'}/>
                    </IconButton>
                    <Typography className={'tab-header-title'}>{title}</Typography>
                </Box>
                {children}
            </Box>
        )
    }

    const getSelectedTheme = () => {
        if (config && config.themeID) {
            let theme = chartState.getTheme(config.themeID);
            if (theme) {
                const defaultPreferences = getDefaultChartPreferences(theme.colorScheme.darkTheme)
                if (!theme.chartPreferences) theme.chartPreferences = defaultPreferences
                // backwards comp, add missing style const
                const newPreferences = JSON.parse(theme.chartPreferences)
                const defaultPreferencesObject = JSON.parse(defaultPreferences)
                if (!newPreferences.kpi) newPreferences.kpi = defaultPreferencesObject.kpi
                theme.chartPreferences = JSON.stringify(newPreferences)
                return theme;
            }
        }
        return undefined
    }

    const getChartPreferences = () => {
        const chartPreferences = getSelectedTheme()?.chartPreferences
        if (chartPreferences) {
            if (typeof chartPreferences === 'string') return JSON.parse(chartPreferences)
            return chartPreferences
        }
        return {}
    }

    const removeUndefined = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map(removeUndefined);
        } else if (obj !== null && typeof obj === 'object') {
            return Object.keys(obj).reduce((acc, key) => {
                const value = removeUndefined(obj[key]);
                if (value !== undefined) {
                    acc[key] = value;
                }
                return acc;
            }, {});
        }
        return obj;
    }

    const isOverride = (key) => {
        const selectedTheme = getSelectedTheme()
        let chartPreferences = selectedTheme?.chartPreferences
        if (selectedTheme && key === CONFIG_OPTIONS.COLORS) {
            const backgroundColor = config.styleOverrides?.backgroundColor
            const colors = config.styleOverrides?.color
            if (backgroundColor || colors) {
                const backgroundColorMismatch = backgroundColor
                    ? selectedTheme.colorScheme?.backgroundColor !== backgroundColor
                    : false
                const colorsMismatch = colors
                    ? !_.isEqual(selectedTheme.colorScheme?.colors.map(c => c.val), colors)
                    : false
                return backgroundColorMismatch || colorsMismatch
            }
        }
        if (chartPreferences) {
            if (typeof chartPreferences === 'string') chartPreferences = JSON.parse(chartPreferences)
            let chartPreferencesCopy = _.cloneDeep(chartPreferences);
            //only done for backwards compatibility. some themes do have a lot of undefined values in their preferences.
            let overridesCopy = removeUndefined(_.cloneDeep(config.styleOverrides));
            const omitFontScaleFactor = (obj) => {
                return _.transform(obj, (result, value, key) => {
                    if (key !== 'fontScaleFactor') {
                        result[key] = _.isObject(value) ? omitFontScaleFactor(value) : value;
                    }
                });
            };
            chartPreferencesCopy = omitFontScaleFactor(chartPreferencesCopy);
            overridesCopy = omitFontScaleFactor(overridesCopy);
            overridesCopy = _.merge({}, chartPreferencesCopy, overridesCopy)
            switch (key) {
                case CONFIG_OPTIONS.KPI_BODY:
                    return !_.isEqual(overridesCopy.kpi.body, chartPreferencesCopy.kpi.body)
                case CONFIG_OPTIONS.KPI_TITLE:
                    return !_.isEqual(overridesCopy.kpi.title, chartPreferencesCopy.kpi.title)
                case CONFIG_OPTIONS.KPI_VALUE:
                    return !_.isEqual(overridesCopy.kpi.value, chartPreferencesCopy.kpi.value)
                case CONFIG_OPTIONS.KPI_COMPARE:
                    return !_.isEqual(overridesCopy.kpi.compare, chartPreferencesCopy.kpi.compare)
                case CONFIG_OPTIONS.TABLE_HEADER:
                    const header_preferences = {
                        backgroundColor: chartPreferencesCopy.table?.backgroundColor,
                        headerBackgroundColor: chartPreferencesCopy.table?.headerBackgroundColor,
                        headerFontFamily: chartPreferencesCopy.table?.headerFontFamily,
                        headerFontColor: chartPreferencesCopy.table?.headerFontColor,
                        headerFontSize: chartPreferencesCopy.table?.headerFontSize
                    }
                    const header_overrides = {
                        backgroundColor: overridesCopy.table?.backgroundColor,
                        headerBackgroundColor: overridesCopy.table?.headerBackgroundColor,
                        headerFontFamily: overridesCopy.table?.headerFontFamily,
                        headerFontColor: overridesCopy.table?.headerFontColor,
                        headerFontSize: overridesCopy.table?.headerFontSize
                    }
                    return !_.isEqual(header_preferences, header_overrides)
                case CONFIG_OPTIONS.TABLE_BODY:
                    const body_preferences = {
                        hideBorder: chartPreferencesCopy.table?.hideBorder,
                        fontFamily: chartPreferencesCopy.table?.fontFamily,
                        fontColor: chartPreferencesCopy.table?.fontColor,
                        fontSize: chartPreferencesCopy.table?.fontSize,
                        backgroundColor: chartPreferencesCopy.table?.backgroundColor
                    }
                    const body_overrides = {
                        hideBorder: overridesCopy.table?.hideBorder,
                        fontFamily: overridesCopy.table?.fontFamily,
                        fontColor: overridesCopy.table?.fontColor,
                        fontSize: overridesCopy.table?.fontSize,
                        backgroundColor: overridesCopy.table?.backgroundColor
                    }
                    return !_.isEqual(body_preferences, body_overrides)
                case CONFIG_OPTIONS.TITLE:
                    return !_.isEqual(chartPreferencesCopy.title, overridesCopy.title)
                case CONFIG_OPTIONS.LEGEND:
                    return !_.isEqual(chartPreferencesCopy.legend, overridesCopy.legend)
                case CONFIG_OPTIONS.TOOLTIP:
                    return !_.isEqual(chartPreferencesCopy.tooltip, overridesCopy.tooltip)
                case CONFIG_OPTIONS.MARGINS:
                    return !_.isEqual(chartPreferencesCopy.grid, overridesCopy.grid)
                case CONFIG_OPTIONS.SPLIT_LINES:
                    return (!_.isEqual(chartPreferencesCopy.xAxis.splitLine, overridesCopy.xAxis.splitLine) ||
                        !_.isEqual(chartPreferencesCopy.yAxis.splitLine, overridesCopy.yAxis.splitLine))
                case CONFIG_OPTIONS.Y_AXIS:
                    return (chartPreferences.yAxis.show !== overridesCopy.yAxis.show ||
                        !_.isEqual(chartPreferencesCopy.yAxis.nameTextStyle, overridesCopy.yAxis.nameTextStyle) ||
                        !_.isEqual(chartPreferencesCopy.yAxis.axisLabel, overridesCopy.yAxis.axisLabel))
                case CONFIG_OPTIONS.X_AXIS:
                    return (chartPreferences.xAxis.show !== overridesCopy.xAxis.show ||
                        !_.isEqual(chartPreferencesCopy.xAxis.nameTextStyle, overridesCopy.xAxis.nameTextStyle) ||
                        !_.isEqual(chartPreferencesCopy.xAxis.axisLabel, overridesCopy.xAxis.axisLabel))
                case CONFIG_OPTIONS.BAR:
                    return !_.isEqual(chartPreferencesCopy.bar, overridesCopy.bar)
                case CONFIG_OPTIONS.LINE:
                    return !_.isEqual(chartPreferencesCopy.line, overridesCopy.line)
                case CONFIG_OPTIONS.AREA:
                    return !_.isEqual(chartPreferencesCopy.line, overridesCopy.line)
                case CONFIG_OPTIONS.WATERFALL:
                    return !_.isEqual(chartPreferencesCopy.waterfall, overridesCopy.waterfall)
                case CONFIG_OPTIONS.PIE:
                    return !_.isEqual(chartPreferencesCopy.pie, overridesCopy.pie)
                case CONFIG_OPTIONS.DONUT:
                    return !_.isEqual(chartPreferencesCopy.donut, overridesCopy.donut)
                case CONFIG_OPTIONS.GAUGE:
                    return !_.isEqual(chartPreferencesCopy.gauge, overridesCopy.gauge)
                case CONFIG_OPTIONS.PICTORIAL:
                    return !_.isEqual(chartPreferencesCopy.pictorialBar, overridesCopy.pictorialBar)
                case CONFIG_OPTIONS.RADIAL:
                    return !_.isEqual(chartPreferencesCopy.radialBar, overridesCopy.radialBar)
            }
        }
        return false
    }

    const hasOverrides = () => {
        let override = false
        CONFIG_BUTTONS.forEach(prop => {
            if (isOverride(prop.key)) override = true
        })
        return override
    }

    const saveChanges = () => {
        setLoading(true)
        const theme = getSelectedTheme()
        let preferences = _.merge(getChartPreferences(), config.styleOverrides)
        const bgColor = preferences.backgroundColor || theme?.colorScheme.backgroundColor || '#FFFFFF'
        const palette = config.styleOverrides.color?.map((c, i) => (
            {name: 'Color ' + (i + 1), val: c.length > 7 ? c.slice(0, -2) : c}
        )) || theme.colorScheme.colors || []
        preferences = _.omit(preferences, ['backgroundColor'])
        preferences = _.omit(preferences, ['color'])
        server.postData({
            "action": "putObject",
            "class": "scoop.presentation.Theme",
            "value": {
                themeID: theme.themeID,
                canvasID: theme.canvasID,
                themeName: theme.themeName,
                colorScheme: {
                    name: theme.themeName,
                    darkTheme: theme.colorScheme.darkTheme,
                    backgroundColor: bgColor,
                    colors: palette
                },
                fonts: theme.fonts,
                chartPreferences: JSON.stringify(preferences)
            }
        }, () => {
            getWorkspaceMetadata(() => {
                const newConfig = {...config, styleOverrides: _.cloneDeep(DEFAULT_CHART_PREFERENCES)}
                setConfig(newConfig)
                handleSaveInsight(newConfig)
                setLoading(false)
            })
        })
    }

    const theme = getSelectedTheme()
    const override = hasOverrides()
    const chartPreferences = getChartPreferences()

    const availableButton = (button) => {
        switch (config.view) {
            case 'table':
                if (![
                    CONFIG_OPTIONS.COLORS,
                    CONFIG_OPTIONS.TABLE_BODY,
                    CONFIG_OPTIONS.TABLE_HEADER
                ].includes(button.key)) return false
                break
            case 'chart':
                if (button.key === CONFIG_OPTIONS.TABLE_BODY || button.key === CONFIG_OPTIONS.TABLE_HEADER) return false
                if (button.key === CONFIG_OPTIONS.BAR && (config.seriesType !== 'column' && config.seriesType !== 'bar')) return false
                if (button.key === CONFIG_OPTIONS.LINE && config.seriesType !== 'line') return false
                if (button.key === CONFIG_OPTIONS.AREA && config.seriesType !== 'area') return false
                if (button.key === CONFIG_OPTIONS.WATERFALL && config.seriesType !== 'waterfall') return false
                if (button.key === CONFIG_OPTIONS.PIE && config.seriesType !== 'pie') return false
                if (button.key === CONFIG_OPTIONS.DONUT && config.seriesType !== 'donut') return false
                if (button.key === CONFIG_OPTIONS.PICTORIAL && config.seriesType !== 'pictorialBar') return false
                if (button.key === CONFIG_OPTIONS.GAUGE && config.seriesType !== 'gauge') return false
                if (button.key === CONFIG_OPTIONS.RADIAL && config.seriesType !== 'radialBar') return false
                break
            case 'kpi':
                if (![
                    CONFIG_OPTIONS.COLORS,
                    CONFIG_OPTIONS.KPI_VALUE,
                    CONFIG_OPTIONS.KPI_COMPARE,
                    CONFIG_OPTIONS.KPI_TITLE,
                    CONFIG_OPTIONS.KPI_BODY
                ].includes(button.key)) return false
                break
        }
        return true
    }

    const renderContent = () => {
        switch (selectedProp) {
            case CONFIG_OPTIONS.MAIN:
                return (
                    <>
                        <ThemeItem theme={theme}/>
                        <Button
                            className={'button-grey switch-theme-button'}
                            onClick={() => {
                                setSelectedProp(CONFIG_OPTIONS.THEMES)
                                setPreviousTheme(config.themeID)
                                setPreviousStyleOverrides(config.styleOverrides)
                            }}
                            style={{marginBottom: override ? '' : '8px'}}
                        >
                            Switch theme
                        </Button>
                        {
                            override &&
                            <Button
                                className={'button-purple switch-theme-button'}
                                onClick={saveChanges}
                                style={{marginBottom: '8px'}}
                                disabled={loading}
                            >
                                {
                                    loading ?
                                        <ScoopLoader/> :
                                        'Save theme'
                                }
                            </Button>
                        }
                        {
                            CONFIG_BUTTONS.map(button => {
                                if (availableButton(button)) {
                                    return (
                                        <Box
                                            onClick={() => setSelectedProp(button.key)}
                                            key={button.key}
                                            className={`style-config-button`}
                                        >
                                            <Box className={'style-config-button-content'}>
                                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                    <Typography
                                                        className={'inter style-config-label'}>{button.label}</Typography>
                                                    {
                                                        isOverride(button.key) &&
                                                        <Box className={'red-dot'}/>
                                                    }
                                                </Box>
                                                <img style={{cursor: 'pointer', pointerEvents: 'auto'}} src={CaretRight}
                                                     alt={'caret-right'}/>
                                            </Box>
                                            <Box className={'style-divider'}/>
                                        </Box>
                                    )
                                }
                            })
                        }
                    </>
                )
            case CONFIG_OPTIONS.COLORS:
                return renderConfigOption({
                    title: 'Color Palette',
                    children: <ColorConfig server={server} theme={theme} config={config} setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.TITLE:
                return renderConfigOption({
                    title: 'Title',
                    children: <TitleConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.LEGEND:
                return renderConfigOption({
                    title: 'Legend',
                    children: <LegendConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.SPLIT_LINES:
                return renderConfigOption({
                    title: 'Grid lines',
                    children: <SplitLineConfig chartPreferences={chartPreferences} config={config}
                                               setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.TOOLTIP:
                return renderConfigOption({
                    title: 'Tooltip',
                    children: <TooltipConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.X_AXIS:
                return renderConfigOption({
                    title: 'X Axis',
                    children: <AxisConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}
                                          axis={'xAxis'}/>
                })
            case CONFIG_OPTIONS.Y_AXIS:
                return renderConfigOption({
                    title: 'Y Axis',
                    children: <AxisConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}
                                          axis={'yAxis'}/>
                })
            case CONFIG_OPTIONS.THEMES:
                return renderConfigOption({
                    title: 'Themes',
                    children: <ThemesConfig
                        server={server}
                        config={config}
                        setConfig={setConfig}
                        setSelectedProp={setSelectedProp}
                        workspaceMetadata={workspaceMetadata}
                        setCreateThemeOpen={setCreateThemeOpen}
                        getWorkspaceMetadata={getWorkspaceMetadata}
                        previousTheme={previousTheme}
                        previousStyleOverrides={previousStyleOverrides}
                    />,
                    onBack: () => {
                        const newConfig = {...config}
                        if (previousTheme) newConfig.themeID = previousTheme
                        else newConfig.themeID = undefined
                        newConfig.styleOverrides = previousStyleOverrides
                        setConfig(newConfig)
                        chartState.setChartStateConfig(newConfig)
                    }
                })
            case CONFIG_OPTIONS.MARGINS:
                return renderConfigOption({
                    title: 'Margins',
                    children: <MarginConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.BAR:
                return renderConfigOption({
                    title: 'Bar',
                    children: <BarConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.LINE:
                return renderConfigOption({
                    title: 'Line',
                    children: <LineConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.AREA:
                return renderConfigOption({
                    title: 'Area',
                    children: <LineConfig chartPreferences={chartPreferences} config={config} setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.WATERFALL:
                return renderConfigOption({
                    title: 'Waterfall',
                    children: <WaterfallConfig config={config} setConfig={setConfig}
                                               chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.PIE:
                return renderConfigOption({
                    title: 'Pie',
                    children: <PieConfig config={config} setConfig={setConfig} pieDonut={'pie'}
                                         chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.DONUT:
                return renderConfigOption({
                    title: 'Donut',
                    children: <PieConfig config={config} setConfig={setConfig} pieDonut={'donut'}
                                         chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.PICTORIAL:
                return renderConfigOption({
                    title: 'Pictorial',
                    children: <PictorialConfig chartState={chartState} config={config} setConfig={setConfig}
                                               chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.GAUGE:
                return renderConfigOption({
                    title: 'Gauge',
                    children: <GaugeConfig chartPreferences={chartPreferences} chartState={chartState} config={config}
                                           setConfig={setConfig}/>
                })
            case CONFIG_OPTIONS.RADIAL:
                return renderConfigOption({
                    title: 'Radial Bar',
                    children: <RadialConfig setConfig={setConfig} config={config} chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.TABLE_HEADER:
                return renderConfigOption({
                    title: 'Header',
                    children: <TableConfig isHeader={true} config={config} setConfig={setConfig}
                                           chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.TABLE_BODY:
                return renderConfigOption({
                    title: 'Body',
                    children: <TableConfig isHeader={false} config={config} setConfig={setConfig}
                                           chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.KPI_TITLE:
                return renderConfigOption({
                    title: 'Title',
                    children: <KPITextConfig kpiProp={'title'} config={config} setConfig={setConfig} chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.KPI_VALUE:
                return renderConfigOption({
                    title: 'Value',
                    children: <KPITextConfig kpiProp={'value'} config={config} setConfig={setConfig} chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.KPI_COMPARE:
                return renderConfigOption({
                    title: 'Compare value',
                    children: <KPITextConfig kpiProp={'compare'} config={config} setConfig={setConfig} chartPreferences={chartPreferences}/>
                })
            case CONFIG_OPTIONS.KPI_BODY:
                return renderConfigOption({
                    title: 'Body',
                    children: <KPIBodyConfig config={config} setConfig={setConfig} chartPreferences={chartPreferences}/>
                })
        }
    }

    return (
        <Box className={'style-tab-wrapper'}>
            {renderContent()}
            <CreateThemeDialog
                createThemeOpen={createThemeOpen}
                setCreateThemeOpen={setCreateThemeOpen}
                workspaceMetadata={workspaceMetadata}
                server={server}
                getWorkspaceMetadata={getWorkspaceMetadata}
            />
        </Box>
    )
}


