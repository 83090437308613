import ReactECharts from "echarts-for-react";
import { ScoopTheme } from "./Style";
import * as React from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { ServerSideGrid } from "./InsightsGrid/ServerSideGrid";
import _, {isNumber} from "lodash";
import { ScoopLoader } from "../common/Spinner/ScoopLoader";
import Typography from "@mui/material/Typography";
import './Insight.css';
import CaretRight from '../../assets/icons/CaretRight.svg';
import CloseIcon from '../../assets/icons/CloseIcon.svg';
import CloseIconWhite from '../../assets/icons/CloseIconWhite.svg';
import {getDefaultChartPreferences} from "../screens/Explorer/DrawerTabs/Style/utils";
import {BAR_DEFAULT_VALUES} from "../screens/Explorer/DrawerTabs/Style/styleConsts";
import {SORTING} from "./consts";
import {KPI} from "./KPI/KPI";

export function Insight({
    chartProperties,
    config,
    setConfig,
    embeddedSizeProps,
    server,
    clickable,
    advanced,
    analyzeChanges,
    handleCreateFilter,
    setColumnLoading,
    workspaceMetadata,
    activePrompts,
    dateFlag,
    theme
}) {

    const [style, setStyle] = React.useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [seriesName, setSeriesName] = React.useState(null);
    const [drillColumn, setDrillColumn] = React.useState(null);
    const [drillingHistory, setDrillingHistory] = React.useState([]);
    const openMenu = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.event.event.currentTarget);
        if (config.view === 'chart') {
            setStyle({
                position: 'absolute',
                left: event.event.event.clientX + 'px',
                top: event.event.event.clientY + 'px',
                minHeight: 300 + 'px'
            })
        } else {
            setDrillColumn(event.drillColumn)
        }
        setSeriesName(event.seriesName);
    };

    const handleTableMenuClose = (event, col) => {
        setAnchorEl(null);
        if (event.key === "Escape" || event.currentTarget.textContent === "") return;
        let newFilter = {
            attributeName: drillColumn.columnName, operator: "Equals", filterValue: {
                values: [seriesName]
            }
        }
        let curFilter = chartProperties.addFilterItem(newFilter);
        config.selectedTableColumns.push(col);
        config.filter = curFilter;
        if (drillingHistory.length === 0) {
            setDrillingHistory([
                { attribute: drillColumn.columnName },
                { attribute: event.currentTarget.textContent, filter: newFilter }
            ])
        } else {
            setDrillingHistory([
                ...drillingHistory,
                { attribute: event.currentTarget.textContent, filter: newFilter }
            ])
        }
        setConfig({ ...config });
    }

    const handleChartMenuClose = (event) => {
        setAnchorEl(null);
        if (event.key === "Escape" || event.currentTarget.textContent === "") return;
        if (chartProperties.config.drillAttribute) {
            chartProperties.config.usedDrillAttributes.push(chartProperties.config.drillAttribute)
            let series = chartProperties.getSeries(seriesName);
            if (series) {
                let newFilter = {
                    attributeName: config.drillAttribute, operator: "Equals", filterValue: {
                        values: [series.category]
                    }
                }
                let curFilter = chartProperties.addFilterItem(newFilter);
                config.drillAttribute = event.currentTarget.textContent;
                config.filter = curFilter;
                setDrillingHistory([
                    ...drillingHistory,
                    { attribute: event.currentTarget.textContent, filter: newFilter }
                ])
                chartProperties.getResults(config, null, activePrompts);
            }
        } else {
            config.drillAttribute = event.currentTarget.textContent;
            setDrillingHistory([
                ...drillingHistory,
                { attribute: event.currentTarget.textContent }
            ])
            chartProperties.getResults(config, null, activePrompts);
        }
        setConfig({ ...config });
    }

    function onChartClick(event) {
        handleMenuClick(event);
    }

    const onEvents = {
        'click': (config.seriesType !== 'pie' && config.seriesType !== 'donut') ? onChartClick : null
    }

    function validChart() {
        if (config.view === 'kpi') return config.selectedItems.length > 0
        if (config.view === 'table' && (config.selectedTableColumns.length > 0 || config.selectedTableKpis.length > 0)) return true
        if (chartProperties.config.seriesType === "scatter" && chartProperties.config.selectedItems && chartProperties.config.selectedItems?.length !== 2) return false;
        return chartProperties.series && chartProperties.series?.length > 0 && ((chartProperties.result.dates && chartProperties.result.dates?.length > 0) || chartProperties.series[0].data?.length > 0);
    }

    const chartSetting = {
        height: embeddedSizeProps ? embeddedSizeProps.height : (window.innerHeight - 112) + "px",
        marginLeft: embeddedSizeProps ? embeddedSizeProps.left : 300,
        marginRight: embeddedSizeProps ? 0 : 300,
        pointerEvents: clickable ? 'all' : 'none'
    };

    const getChartDrillItems = () => {
        let drillAtts = []
        if (chartProperties.drillAttributes?.length > 0) {
            drillAtts = chartProperties.drillAttributes?.map((item) => {
                if (
                    item === chartProperties.categoryAxis ||
                    item === chartProperties.config.drillAttribute ||
                    !chartProperties.getAvailableDrillAttributes().includes(item) ||
                    chartProperties.config.selectedTableColumns.includes(item)
                ) {
                    return null;
                }
                return (<MenuItem key={item} value={item} onClick={handleChartMenuClose}>{item}</MenuItem>);
            })
        }
        let changeDrillAtts = []
        if (advanced && analyzeChanges && chartProperties.changeDrillAttributes?.length > 0) {
            changeDrillAtts = advanced && analyzeChanges && chartProperties.changeDrillAttributes.map((item) => {
                if (item === chartProperties.categoryAxis || item === chartProperties.config.drillAttribute) {
                    return null;
                }
                return (<MenuItem key={item} value={item} onClick={handleChartMenuClose}>{item}</MenuItem>);
            })
        }
        return [...drillAtts, ...changeDrillAtts]
    }

    const getTableDrillItems = () => {
        const tables = []
        workspaceMetadata?.inboxes?.forEach(inbox => {
            inbox.tables.forEach(table => {
                if (config.selectedTables.includes(table.reportSeriesTableID)) tables.push(table)
            })
        })
        const columns = _.intersection(...tables.map(table => table.columns.map(col => ({ ...col, reportSeriesTableID: table.reportSeriesTableID }))))
        const availableDrills = columns.filter(column => !column.isMeasure && !config.selectedTableColumns.some(col => col.columnName === column.columnName))
        return availableDrills.map(col => <MenuItem key={col.columnName} onClick={(e) => handleTableMenuClose(e, col)}>{col.columnName}</MenuItem>)
    }

    const handleDeleteDrillingStep = (step, i) => {
        const newConfig = { ...config }
        const newDrillingHistory = [...drillingHistory]
        if (config.view === 'chart') {
            if (drillingHistory.length === 1) {
                // empty drill attribute from config
                newConfig.drillAttribute = undefined
                // empty drill history
                setDrillingHistory([])
            } else {
                // move drill attribute
                newConfig.drillAttribute = newDrillingHistory[i - 1].attribute
                // remove step filter
                newConfig.filter = chartProperties.removeFilterItem(step.filter)
                // remove step from history
                newDrillingHistory.splice(i, 1)
                // enable drill attribute again
                newConfig.usedDrillAttributes.splice(newConfig.usedDrillAttributes.indexOf(step.attribute), 1)
                setDrillingHistory(newDrillingHistory)
            }
            setConfig(newConfig)
            chartProperties.getResults(newConfig, null, activePrompts)
        } else {
            // remove column
            const deleteIndex = newConfig.selectedTableColumns.findIndex(col => col.columnName === step.attribute)
            newConfig.selectedTableColumns.splice(deleteIndex, 1)
            // remove step filter
            newConfig.filter = chartProperties.removeFilterItem(step.filter)
            if (drillingHistory.length === 2) {
                // empty drill history
                setDrillingHistory([])
            } else {
                // remove step from history
                newDrillingHistory.splice(i, 1)
                setDrillingHistory(newDrillingHistory)
            }
            setConfig(newConfig)
        }
    }

    const navigateToStep = (step, i) => {
        const newConfig = { ...config }
        let newDrillingHistory = [...drillingHistory]
        const toDeleteSteps = newDrillingHistory.slice(i + 1)
        if (config.view === 'chart') {
            toDeleteSteps.forEach(s => {
                // remove filters from steps > selected step
                newConfig.filter = chartProperties.removeFilterItem(s.filter)
                // enable drill attribute again
                newConfig.usedDrillAttributes.splice(newConfig.usedDrillAttributes.indexOf(s.attribute), 1)
            })
            // move drill attribute to selected step
            newConfig.drillAttribute = step.attribute
            // remove left steps > selected step
            newDrillingHistory = newDrillingHistory.slice(0, i + 1)
            setDrillingHistory(newDrillingHistory)
            setConfig(newConfig)
            chartProperties.getResults(newConfig, null, activePrompts)
        } else {
            toDeleteSteps.forEach(s => {
                // remove column
                const deleteIndex = newConfig.selectedTableColumns.findIndex(col => col.columnName === s.attribute)
                newConfig.selectedTableColumns.splice(deleteIndex, 1)
                // remove step filter
                newConfig.filter = chartProperties.removeFilterItem(s.filter)
            })
            // remove left steps > selected step
            newDrillingHistory = newDrillingHistory.slice(0, i + 1)
            setDrillingHistory(newDrillingHistory)
            setConfig(newConfig)
        }
    }

    const isStepClickable = (i) => {
        if (config.view === 'chart') return i < drillingHistory.length - 1
        else return i > 0 && i < drillingHistory.length - 1
    }

    const applyFontScale = (overrides, width, height) => {
        if (overrides.title?.textStyle?.fontScaleFactor?.x && overrides.title.textStyle.fontScaleFactor?.y) {
            overrides.title.textStyle.fontSize =
                (overrides.title.textStyle.fontScaleFactor.x * width +
                    overrides.title.textStyle.fontScaleFactor.y * height) / 2;
        }

        if (overrides.legend?.textStyle?.fontScaleFactor?.x && overrides.legend.textStyle.fontScaleFactor?.y) {
            overrides.legend.textStyle.fontSize =
                (overrides.legend.textStyle.fontScaleFactor.x * width +
                    overrides.legend.textStyle.fontScaleFactor.y * height) / 2;
        }

        if (overrides.xAxis?.axisLabel?.fontScaleFactor?.x && overrides.xAxis.axisLabel.fontScaleFactor?.y) {
            overrides.xAxis.axisLabel.fontSize =
                (overrides.xAxis.axisLabel.fontScaleFactor.x * width +
                    overrides.xAxis.axisLabel.fontScaleFactor.y * height) / 2;
        }

        if (overrides.yAxis?.axisLabel?.fontScaleFactor?.x && overrides.yAxis.axisLabel.fontScaleFactor?.y) {
            overrides.yAxis.axisLabel.fontSize =
                (overrides.yAxis.axisLabel.fontScaleFactor.x * width +
                    overrides.yAxis.axisLabel.fontScaleFactor.y * height) / 2;
        }

        if (overrides.xAxis?.nameTextStyle?.fontScaleFactor?.x && overrides.xAxis.nameTextStyle.fontScaleFactor?.y) {
            overrides.xAxis.nameTextStyle.fontSize =
                (overrides.xAxis.nameTextStyle.fontScaleFactor.x * width +
                    overrides.xAxis.nameTextStyle.fontScaleFactor.y * height) / 2;
        }

        if (overrides.yAxis?.nameTextStyle?.fontScaleFactor?.x && overrides.yAxis.nameTextStyle.fontScaleFactor?.y) {
            overrides.yAxis.nameTextStyle.fontSize =
                (overrides.yAxis.nameTextStyle.fontScaleFactor.x * width +
                    overrides.yAxis.nameTextStyle.fontScaleFactor.y * height) / 2;
        }
    }

    const getOptionWithOverrides = () => {
        let option = _.cloneDeep(chartProperties.getOption())
        if (theme) option = _.cloneDeep(chartProperties.getOption(theme.themeID))
        let overrides = _.cloneDeep(config.styleOverrides)
        if (theme && theme.chartPreferences) {
            let preferences = typeof theme.chartPreferences === 'string' ? JSON.parse(theme.chartPreferences) : theme.chartPreferences
            overrides = _.merge(_.cloneDeep(preferences), _.cloneDeep(overrides))
        } else {
            option = _.merge(_.cloneDeep(JSON.parse(getDefaultChartPreferences())), option)
        }
        // remove axis for pie and donut
        if (config.seriesType === 'pie' || config.seriesType === 'donut' || config.seriesType === 'gauge' || config.seriesType === 'radialBar') {
            overrides.xAxis.show = false
            overrides.yAxis.show = false
            overrides.legend.icon = 'none'
        }
        // apply font scale
        if (embeddedSizeProps) {
            const height = embeddedSizeProps.containerSize.height;
            const width = embeddedSizeProps.containerSize.width;
            applyFontScale(overrides, width, height);
        }
        //check x axis.show is undefined
        if (overrides.xAxis.show === undefined) {
            overrides.xAxis.show = true
        }
        //check y axis.show is undefined
        if (overrides.yAxis.show === undefined) {
            overrides.yAxis.show = true
        }
        // distribute axis configs
        if ((Array.isArray(option.yAxis) && overrides.yAxis)) {
            option.yAxis.forEach((axisObject, i) => {
                option.yAxis[i] = _.merge(axisObject, overrides.yAxis)
            })
            overrides = _.omit(overrides, ['yAxis'])
        } else {
            if (config.seriesType === 'pictorialBar') option.yAxis = { ...option.yAxis, ...overrides.yAxis }
        }
        if (Array.isArray(option.xAxis) && overrides.xAxis) {
            option.xAxis.forEach((axisObject, i) => {
                option.xAxis[i] = { ...axisObject, ...overrides.xAxis }
            })
            overrides = _.omit(overrides, ['xAxis'])
        } else {
            if (config.seriesType === 'pictorialBar') option.xAxis = { ...option.xAxis, ...overrides.xAxis }
        }
        // apply bar/waterfall configs
        if (config.seriesType === 'waterfall') {
            option.series.forEach((s, i) => {
                if (s.name === 'positive') {
                    option.series[i].itemStyle = { color: overrides.waterfall.upColor }
                    option.series[i].data[0] = {
                        ...option.series[i].data[0],
                        itemStyle: { color: overrides.waterfall.startColor }
                    }
                }
                if (s.name === 'negative') {
                    option.series[i].itemStyle = { color: overrides.waterfall.downColor }
                    const lastIndex = option.series[i].data.length - 1
                    option.series[i].data[lastIndex] = {
                        ...option.series[i].data[lastIndex],
                        itemStyle: { color: overrides.waterfall.endColor }
                    }
                }
            })
        } else {
            if (option.series.some(s => s.type === 'bar')) {
                option.series.forEach((s, i) => {
                    option.series[i] = { ...s, ...overrides.bar }
                })
            }
        }
        // apply line configs
        if (option.series.some(s => s.type === 'line')) {
            option.series.forEach((s, i) => {
                option.series[i] = { ...s, ...overrides.line }
            })
        }
        if (config.seriesType === 'radialBar') {
            overrides.radialBar = _.merge(JSON.parse(getDefaultChartPreferences()).radialBar, overrides.radialBar)
            option = {
                ...option,
                angleAxis: {
                    ...overrides.radialBar.angleAxis,
                    data: { ...chartProperties?.categoryAxisValues }
                },
                polar: overrides.radialBar.polar,
                radiusAxis: overrides.radialBar.radiusAxis
            }
            const showAsStacked = overrides.radialBar.stack
            option.series.forEach((s, i) => {
                if (showAsStacked) option.series[i].stack = 'total'
                option.series[i].coordinateSystem = 'polar'
                option.series[i].type = 'bar'
                option.series[i].label = {
                    show: true,
                    position: 'middle',
                    formatter: '{b}: {c}',
                    fontSize: 6
                }
                option.series[i] = {
                    ...s,
                    emphasis: overrides.radialBar.emphasis,
                    itemStyle: overrides.radialBar.itemStyle,
                    barWidth: overrides.radialBar.barWidth,
                }
            })
        }
        // apply pictorial configs
        if (config.seriesType === 'pictorialBar') {
            const xAxisCopy = _.cloneDeep(option.xAxis);
            const yAxisCopy = _.cloneDeep(option.yAxis);

            if (overrides.pictorialBar.showAsBar) {
                option.xAxis = Array.isArray(yAxisCopy) ? yAxisCopy : { ...yAxisCopy };
                option.yAxis = Array.isArray(xAxisCopy) ? xAxisCopy : { ...xAxisCopy };

                option.series.forEach(seriesItem => {
                    if (seriesItem.yAxisIndex) {
                        seriesItem.xAxisIndex = seriesItem.yAxisIndex;
                        delete seriesItem.yAxisIndex;
                    }
                });
            } else {
                option.xAxis = Array.isArray(xAxisCopy) ? xAxisCopy : { ...xAxisCopy };
                option.yAxis = Array.isArray(yAxisCopy) ? yAxisCopy : { ...yAxisCopy };

                option.series.forEach(seriesItem => {
                    if (seriesItem.xAxisIndex) {
                        seriesItem.yAxisIndex = seriesItem.xAxisIndex;
                        delete seriesItem.xAxisIndex;
                    }
                });
                option.yAxis.forEach((axisObject, i) => {
                    option.yAxis[i] = { ...axisObject, ...overrides.pictorialBar.xAxis }
                })
            }
            option.series.forEach((series, seriesIndex) => {
                if (!overrides.pictorialBar.data[seriesIndex]) {
                    overrides.pictorialBar.data[seriesIndex] = [];
                }

                series.barGap = overrides.pictorialBar.barGap;
                series.barCategoryGap = overrides.pictorialBar.barCategoryGap;

                series.data.forEach((d, i) => {
                    if (typeof d === 'number') {
                        series.data[i] = Object.assign({ value: d }, overrides.pictorialBar.data[seriesIndex][i]);
                    } else {
                        series.data[i] = Object.assign({}, d, overrides.pictorialBar.data[seriesIndex][i]);
                    }
                });
            });
        }
        // apply pie/donut configs
        if (config.seriesType === 'donut') {
            overrides.donut = _.merge(JSON.parse(getDefaultChartPreferences()).donut, overrides.donut)
            option.series.forEach((s, i) => {
                option.series[i] = { ...s, ...overrides.donut }
            })
        } else {
            overrides.pie = _.merge(JSON.parse(getDefaultChartPreferences()).pie, overrides.pie)
            if (option.series.some(s => s.type === 'pie')) {
                option.series.forEach((s, i) => {
                    option.series[i] = { ...s, ...overrides.pie }
                })
            }
        }
        if (config.seriesType === 'gauge') {
            overrides.gauge = _.merge(JSON.parse(getDefaultChartPreferences()).gauge, overrides.gauge)
            overrides.tooltip.show = false
            overrides.legend.show = false
            option.series.forEach((s, i) => {
                s.type = 'gauge'
                if (overrides.gauge?.data && overrides.gauge.data[0]?.value && isNumber(overrides.gauge.data[0])) {
                    option.series[i].data = overrides.gauge?.data
                } else {
                    const name = chartProperties?.categoryAxisValues[0] || ''
                    let value = ((chartProperties?.series[0]?.data[0]?.value || chartProperties?.series[0]?.data[0]) * 100 / chartProperties?.series[0]?.data.reduce((a, b) => a + (b.value || b), 0)).toFixed(3);
                    option.series[i].data = [{ value, name }]
                }
                option.series[i] = { ...s, ...overrides.gauge, data: option.series[i].data }
            })
        }
        if (config.seriesType === 'column' && chartProperties.categoryLegendData?.length > 1 && !config.stacked) {
            let divisor = 1;
            if (chartProperties.categoryAxisValues) divisor = chartProperties.categoryAxisValues?.length - 1;
            if (chartProperties.categoryLegendData) divisor = divisor + chartProperties.categoryLegendData?.length - 1;
            option.series.forEach((s, i) => {
                let barWidth = (overrides.bar.barWidth?.split('%')[0] || BAR_DEFAULT_VALUES.barWidth) / divisor
                if (barWidth) {
                    option.series[i] = {...s, ...overrides.bar, barWidth: `${barWidth}%`}
                }
            })
        }
        // apply max legends
        const max = overrides.legend.maxLegends || 10
        // TO-DO , Nesti/Pepe  Gabe put this try-catch to prevent page crash when data is undefined
        try {
            if (max !== 'all') option.legend.data = option?.legend?.data?.slice(0, max)
        } catch (e) {
            //console.log("data undefined, e=", e)
        }
        // omit props we dont wanna merge
        ['waterfall', 'bar', 'line', 'pie', 'donut', 'pictorialBar', 'gauge', 'radialBar', 'table'].forEach(key => {
            overrides = _.omit(overrides, [key]);
        });
        option = _.merge(option, overrides)
        // apply sorting
        if (config.categoryAxis !== 'Time' && option.series.length === 1 && config.sorting !== SORTING.NAT) {
            let axis = 'xAxis'
            if (config.seriesType === 'bar') axis = 'yAxis'
            let tempData = []
            if (config.seriesType === 'pictorialBar') {
                tempData = option[axis].data.map((cat, i)=> ({cat: cat, val: option.series[0].data[i].value}))
            } else {
                tempData = option[axis].data.map((cat, i)=> ({cat: cat, val: option.series[0].data[i]}))
            }
            if (config.sorting === SORTING.ASC) tempData.sort((a, b) => a.val - b.val)
            if (config.sorting === SORTING.DESC) tempData.sort((a, b) => b.val - a.val)
            option[axis].data = tempData.map(d => d.cat)
            if (config.seriesType === 'pictorialBar') {
                option.series[0].data = tempData.map((d, i) => (
                    {value: d.val, name: option[axis].data[i]}
                ))
            } else {
                option.series[0].data = tempData.map(d => d.val)
            }
        }
        return option
    }

    const renderInsight = () => {
        switch (config.view) {
            case 'table':
                return (
                    <ServerSideGrid
                        handleMenuClick={handleMenuClick}
                        config={config}
                        setConfig={setConfig}
                        embeddedSizeProps={embeddedSizeProps}
                        server={server}
                        clickable={clickable}
                        prompts={activePrompts}
                        handleCreateFilter={handleCreateFilter}
                        setColumnLoading={setColumnLoading}
                        dateFlag={dateFlag}
                        theme={theme}
                    />
                )
            case 'chart':
                return (
                    <ReactECharts
                        option={getOptionWithOverrides()}
                        notMerge={true}
                        lazyUpdate={true}
                        style={chartSetting}
                        theme={ScoopTheme}
                        onEvents={onEvents}
                    />
                )
            case 'kpi':
                return (
                    <Box sx={{display: 'flex', width: '100%', height: '100%'}}>
                        <KPI
                            config={config}
                            setConfig={setConfig}
                            server={server}
                            embeddedSizeProps={embeddedSizeProps}
                            theme={theme}
                        />
                    </Box>
                )
        }
    }

    const renderNoData = () => {
        return (
            <Box sx={{
                height: '100%',
                width: '100%',
                display: 'grid',
                placeContent: 'center',
                color: theme?.colorScheme?.darkTheme ? 'white' : 'black'
            }}>
                <Typography className={'inter'}>No data found for current configuration</Typography>
            </Box>
        )
    }

    return (
        <>
            {
                embeddedSizeProps && drillingHistory.length > 0 &&
                <Box className={'drilling-breadcrumbs-container'}>
                    {
                        drillingHistory.map((step, i) => (
                            <Box
                                key={i + step.attribute}
                                className={'drill-step'}
                                sx={{ color: theme?.colorScheme?.darkTheme ? 'white' : 'black' }}
                            >
                                <Typography
                                    className={`inter ${isStepClickable(i) ? 'clickable-step' : ''}`}
                                    mr={'5px'}
                                    fontSize={'12px'}
                                    onClick={() => isStepClickable(i) ? navigateToStep(step, i) : null}
                                >
                                    {
                                        i < drillingHistory.length - 1 ?
                                            step.attribute + ' = ' + drillingHistory[i + 1].filter.filterValue.values[0] :
                                            step.attribute
                                    }
                                </Typography>
                                {
                                    i + 1 === drillingHistory.length &&
                                    <IconButton sx={{ padding: '4px' }} onClick={() => handleDeleteDrillingStep(step, i)}>
                                        <img src={theme?.colorScheme?.darkTheme ? CloseIconWhite : CloseIcon} height={12} alt={'delete'} />
                                    </IconButton>
                                }
                                {
                                    i < drillingHistory.length - 1 &&
                                    <img src={CaretRight} alt={'caret-right'} style={{ marginRight: '5px' }} />
                                }
                            </Box>
                        ))
                    }
                </Box>
            }
            {
                chartProperties.config.loading ?
                    <Box sx={{ height: '100%', display: 'grid', placeContent: 'center' }}>
                        <ScoopLoader size={56} />
                    </Box> :
                    (validChart() ? renderInsight() : renderNoData())
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                container={document.getElementById('slide-container')}
                open={openMenu}
                onClose={handleChartMenuClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                style={style}
            >
                {config.view === 'table' ? getTableDrillItems() : getChartDrillItems()}
            </Menu>
        </>
    );
}
