import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ScoopLogo from "../../assets/logo/ScoopLogo.svg";
import SwitchWorkspaceDialog from "../SwitchWorkspaceDialog";
import UserProfileModal from "./Profile";
import { navbarItems } from "./navbarItems";
import NavbarItem from "./NavBarItem";
import Bell from "../../assets/icons/Bell.svg";
import UserCircle from "../../assets/icons/UserCircle.svg";
import './NavBar.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CaretUpDown from "../../assets/icons/CaretUpDown.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";
import { ROUTES } from "../../router/routes";
import { useCallback, useEffect } from "react";
import { useApi } from "../../api/api";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from "@mui/material/Typography";
import {Tooltip} from "@mui/material";
import {toggleSwitchWorkspaceModal} from "../../store/actions/uiActions";

const NavBar = ({ workspaceName }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const switchWorkspaceModalOpen = useSelector((state) => state.ui.switchWorkspaceModalOpen);
    const userID = useSelector((state) => state.auth.userID);
    const userInfo = useSelector((state) => state.auth);
    const { canvasID } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMenuOpen = Boolean(anchorEl);
    const [showUserProfile, setShowUserProfile] = React.useState(false);
    const { postData: postDataDefault } = useApi();
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);

    useEffect(() => {
        if (!workspaceID && !isGuestMode) {
            dispatch(toggleSwitchWorkspaceModal(true));
        } else {
            dispatch(toggleSwitchWorkspaceModal(false));
        }
    }, [workspaceID, isGuestMode]);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        dispatch(toggleSwitchWorkspaceModal(false));
    };

    const handleProfileItemClick = () => {
        setAnchorEl(null);
        setShowUserProfile(true);
    }

    const handleLogout = () => {
        dispatch(logout())
        navigate(ROUTES.LOGIN)
    }

    const menuId = 'primary-search-account-menu';

    const retrieveUserInformation = useCallback(async () => {
        if (!userID || isGuestMode) return;
        const action = {
            "action": "getObjects",
            "attributeName": "userID",
            "attributeValue": userID,
            "class": "scoop.user.User"
        };
        const { objects: result } = await postDataDefault(action);
        if (result && result.length > 0) return result[0];
    }, [postDataDefault, userID]);

    const retrieveUserWorkspaces = useCallback(async () => {
        if (!userID || isGuestMode) return;
        const action = {
            "action": "getObjects",
            "attributeName": "userID",
            "class": "scoop.workspace.Workspace",
            "attributeValue": userID,
            "relatedClass": "scoop.workspace.WorkspaceUser"
        };
        const { objects: result } = await postDataDefault(action);
        if (result && result.length > 0) return result;
    }, [postDataDefault, userID]);

    useEffect(() => {
        const fetchAndDispatchUserInfo = async () => {
            let userData = await retrieveUserInformation();
            let workspaces = await retrieveUserWorkspaces();
            if (userData && workspaces) {
                dispatch({
                    type: 'UPDATE_USER_INFO',
                    user: {
                        attributes: {
                            accountID: userData.accountID,
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            googleAccount: userData.googleAccount,
                            workspacesAccessible: workspaces,
                        },
                    },
                });
            }
        };
        fetchAndDispatchUserInfo();
    }, [userID, workspaceID, canvasID]);

    return (
        <>
            <SwitchWorkspaceDialog
                shouldOpen={switchWorkspaceModalOpen}
                onClose={handleMenuClose}
            />
            <UserProfileModal open={showUserProfile} userInfo={userInfo} handleClose={() => setShowUserProfile(false)} />
            {
                !isGuestMode &&
                <AppBar>
                    <Toolbar className={'navbar'}>
                        <Box className={'scoop-logo-container'}>
                            <img src={ScoopLogo} alt="Scoop Logo" onClick={() => navigate(ROUTES.CANVAS_DASHBOARD)} />
                        </Box>
                        <Box className={'navbar-items-container'}>
                            <Tooltip title={'Switch workspace'}>
                                <Box
                                    onClick={() => dispatch(toggleSwitchWorkspaceModal(true))}
                                    className={'navbar-item'}
                                    sx={{ padding: '0 16px' }}
                                >
                                    <Typography className={'inter'}>{workspaceName}</Typography>
                                    <img src={CaretUpDown} alt="Switch Workspace" />
                                </Box>
                            </Tooltip>
                            {
                                navbarItems.map((item, index) => (
                                    <NavbarItem
                                        key={index}
                                        icon={item.icon}
                                        label={item.label}
                                        route={item.route}
                                        selected={location.pathname.includes(item.route)}
                                        onClick={() => navigate(item.route)}
                                    />
                                ))
                            }
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{display: {xs: 'none', md: 'flex', gap: '14px'}}}>
                            <a href="https://www.scoopanalytics.com/help" target="_blank" rel="noreferrer">
                                <Box
                                    aria-label="show new notifications"
                                    className={'navbar-item'}
                                >
                                    <HelpOutlineIcon sx={{color: 'white', fontWeight: 400}}/>
                                </Box>
                            </a>
                            <Box
                                className={'navbar-item'}
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                sx={{color: 'white'}}
                            >
                                <img src={UserCircle} alt={"User Circle"}/>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            }
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{ top: 30 }}
            >
                <MenuItem onClick={handleProfileItemClick}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
        </>

    )
}
export default NavBar
