import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Chip,
    DialogContentText, FormControl, FormControlLabel,
    MenuItem, Radio, RadioGroup, TextField, Tooltip,
    Typography,
} from "@mui/material";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dialog from "../common/Dialog/Dialog";
import Button from "../common/Button/Button";
import Input from "../common/Input/Input";
import Selector from "../common/Selector/Selector";
import {useEffect, useState} from "react";
import {FilterDialog} from "./FilterDialog";
import {getStringForFilter, unpackFilters} from "./Filter";
import _ from "lodash";
import Cursor from "../../assets/icons/Cursor.svg";

export function MetricDialog({
                                 isNew,
                                 metric,
                                 setMetric,
                                 workspaceMetadata,
                                 setWorkspaceMetadata,
                                 server,
                                 getWorkspaceMetadata,
                                 isConfirmDelete,
                                 setIsConfirmDelete,
                                 handleUnselectDeletedMetric,
                                 chartState,
                             }) {

    const NO_FILTER = "No Filter Applied";
    let [columnSource, setColumnSource] = React.useState();
    let [savedFilter, setSavedFilter] = React.useState(() => {
        if (metric?.savedFilterID && workspaceMetadata) {
            const filter = workspaceMetadata.filters.find(f => f.savedFilterKey === metric.savedFilterID);
            return filter ? filter.filterName : NO_FILTER;
        }
        return NO_FILTER;
    });
    const [selectExistingFilters, setSelectExistingFilters] = useState("Existing");
    const [filterDialogOpen, setFilterDialogOpen] = useState(false)
    const [selectedFilterColumn, setSelectedFilterColumn] = useState(null);
    const [editState, setEditState] = useState(null);
    const chartProperties = _.cloneDeep(chartState);
    const [config, setConfig] = useState(chartProperties.config)
    const [errorMessage, setErrorMessage] = useState(null);
    const [calculatedValue, setCalculatedValue] = useState(null);

    const unpackedFilters = unpackFilters(config?.filter);

    useEffect(() => {
        if (workspaceMetadata && metric) {
            const filter = workspaceMetadata.filters.find(f => f.savedFilterKey === metric.savedFilterID);
            if (filter && filter.filterName !== savedFilter) {
                setSavedFilter(filter.filterName);
            }
        }
    }, [workspaceMetadata, metric]);

    function getColumnSource() {
        let inbox = null;
        let table = null;
        let column = null;
        let isCount = false;
        if (workspaceMetadata && metric && !metric.isDerived) {
            for (let i = 0; i < workspaceMetadata.inboxes.length; i++) {
                for (let j = 0; j < workspaceMetadata.inboxes[i].tables.length; j++) {
                    if (workspaceMetadata.inboxes[i].tables[j].reportSeriesTableID === metric.reportSeriesTableID) {
                        inbox = workspaceMetadata.inboxes[i];
                        table = workspaceMetadata.inboxes[i].tables[j];
                        for (let k = 0; k < table.columns.length; k++) {
                            if (table.columns[k].columnName === metric.measureName) {
                                column = table.columns[k];
                            }
                        }
                        if (!column && metric.measureName === "Count") {
                            isCount = true;
                        }
                        break;
                    }
                }
                if (table) {
                    break;
                }
            }
        }
        return {inbox: inbox, table: table, column: column, isCount: isCount, dateKey: metric.dateKeyIndex};
    }

    function handleClose() {
        setMetric(null);
        setColumnSource(null);
        setIsConfirmDelete(false);
        setSavedFilter(null);
        setErrorMessage(null);
        setCalculatedValue(null);
    }

    function handleSave() {

        for (let i = 0; i < workspaceMetadata.kpis.length; i++) {
            if (workspaceMetadata.kpis[i].metricName === metric.metricName) {
                workspaceMetadata.kpis[i] = metric;
            }
        }
        setWorkspaceMetadata({...workspaceMetadata});
        if (!metric.derived) {
            metric.reportSeriesTableID = columnSource.table.reportSeriesTableID;
            metric.measureName = columnSource.isCount ? "Count" : columnSource.column.columnName;
            if (metric.aggRule === "CumulativeSum") {
                metric.cumulative = true;
                metric.aggRule = "Sum";
            }
            if (metric.aggRule === "CumulativeCount") {
                metric.cumulative = true;
                metric.aggRule = "Count";
            }
            if (savedFilter) {
                if (savedFilter === NO_FILTER) {
                    metric.savedFilterID = null
                } else {
                    for (let i = 0; i < workspaceMetadata.filters.length; i++) {
                        if (workspaceMetadata.filters[i].filterName === savedFilter) {
                            metric.savedFilterID = workspaceMetadata.filters[i].savedFilterKey;
                            break;
                        }
                    }
                }
            }
            metric.dateKeyIndex = columnSource.dateKey ? columnSource.dateKey : 0;
        } else {
            metric.measureName = undefined;
            metric.reportSeriesTableID = 'Metric';
        }
        server.postData({
            "action": "putObject", "class": "scoop.metric.Metric", "value": metric
        }, () => {
            if (config) chartProperties.getResults(config);
            getWorkspaceMetadata();
        });
        setMetric(null);
        setColumnSource(null);
        setIsConfirmDelete(false);
        setSavedFilter(null);
        setConfig(null)
        setFilterDialogOpen(false)
        setErrorMessage(null);
        setCalculatedValue(null);
    }

    function handleDelete() {
        server.postData({
            "action": "deleteObject", "class": "scoop.metric.Metric", "key": metric.metricKey
        }, () => {
            handleUnselectDeletedMetric(metric)
            getWorkspaceMetadata();
        });
        setMetric(null);
        setColumnSource(null);
        setIsConfirmDelete(false);
        setSavedFilter(null);
        setErrorMessage(null);
        setCalculatedValue(null);
    }

    function getPeriodPlural(period) {
        switch (period) {
            case "Daily":
                return "days";
            case "Weekly":
                return "weeks";
            case "Monthly":
                return "months";
            case "Quarterly":
                return "quarters";
            case "Yearly":
                return "years";
            default:
                return null;
        }
    }

    if (workspaceMetadata && metric && !columnSource) {
        columnSource = getColumnSource();
        setColumnSource(columnSource);
    }

    const handleResetState = () => {
        setFilterDialogOpen(false);
        setSelectedFilterColumn(null);
        setEditState(null);
    }

    const FilterAccordion = ({workspaceMetadata}) => {

        const [name, setName] = useState('')
        const deleteFilterClick = (event, filter) => {
            if (filter === "Custom") {
                config.timeRange = "Last Year";
                config.from = null;
                config.to = null;
            } else {
                config.filter = chartProperties.removeFilterItem(filter);
                config.usedDrillAttributes = config.usedDrillAttributes.filter(att => att !== filter.attributeName)
            }
            chartProperties.getResults(config);
            setConfig({...config});
        }

        async function handleSaveFilter() {
            await server.postData({
                    "action": "putObject",
                    "class": "scoop.queryfilter.SavedFilter",
                    "value": {
                        filterName: name,
                        workspaceID: server.workspaceID,
                        filter: config.filter
                    }
                }, findNewFilter
            );
        }

        const findNewFilter = async () => {
            await server.postData({
                "action": "getWorkspaceMetadata",
            }, (result) => {
                setWorkspaceMetadata(result);
                setSavedFilter(result.filters.find(f => f.filterName === name).filterName);
                setSelectedFilterColumn(null)
                setEditState(null)
                setConfig(null)
                setSelectExistingFilters("Existing")
            });
        }


        const editFilterClick = (f) => {
            setEditState(f)
            setSelectedFilterColumn({columnName: f.attributeName})
            setFilterDialogOpen(true)
        }

        return (
            <AccordionDetails>
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="connect-by"
                        name="connectBy"
                        value={selectExistingFilters}
                        onChange={(e) => setSelectExistingFilters(e.target.value)}
                    >
                        <FormControlLabel
                            value="Existing"
                            control={<Radio sx={{"&.Mui-checked": {color: "#E50B54"}}}/>}
                            label="Select existing filter"
                        />
                        <FormControlLabel
                            value="New"
                            control={<Radio sx={{"&.Mui-checked": {color: "#E50B54"}}}/>}
                            label="Add new filter"
                        />
                    </RadioGroup>
                </FormControl>
                {
                    selectExistingFilters === "Existing" && (
                        <Box sx={{mt: 2}}>
                            <Selector sx={{width: '50%', minWidth: 400}}
                                      value={savedFilter}
                                      onChange={(event) => {
                                          setSavedFilter(event.target.value);
                                      }}
                                      label="Select Saved Filter to Use"
                            >
                                <MenuItem value={NO_FILTER}>No Filter Applied</MenuItem>
                                {workspaceMetadata?.filters?.map((filter) => {
                                    return (
                                        <MenuItem key={filter.filterName} value={filter.filterName}>
                                            {filter.filterName}
                                        </MenuItem>
                                    );
                                })}
                            </Selector>
                            <Box sx={{mt: 1, maxWidth: '750px'}}>
                                {
                                    (savedFilter !== NO_FILTER && savedFilter !== null) &&
                                    <Typography sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                    }}>
                                        {`${savedFilter} details:`}
                                    </Typography>
                                }
                                {
                                    workspaceMetadata?.filters?.length > 0 &&
                                    unpackFilters(workspaceMetadata.filters.find(f => f.filterName === savedFilter)?.filter).map((f) => {
                                        let s = getStringForFilter(f);
                                        return (
                                            <Tooltip title={s} arrow>
                                                <Chip
                                                    key={s}
                                                    label={s}
                                                    sx={{m: 0.5}}
                                                />
                                            </Tooltip>
                                        );
                                    })
                                }
                            </Box>
                        </Box>

                    )
                }
                {
                    selectExistingFilters === "New" && (
                        <Box sx={{mt: 2}}>
                            {
                                unpackedFilters?.length > 0 &&
                                <Box sx={{maxWidth: '50%', mb: 1, display: 'flex', gap: '8px'}}>
                                    <Input
                                        label={"New Filter Name"}
                                        onChange={(event) => setName(event.target.value)}
                                        value={name}
                                    />
                                </Box>
                            }
                            {
                                unpackedFilters?.map((f) => {
                                    let s = getStringForFilter(f);
                                    return (
                                        <Tooltip title={s} arrow>
                                            <Chip
                                                key={s}
                                                label={s}
                                                onDelete={(event) => deleteFilterClick(event, f)}
                                                sx={{m: 0.5}}
                                                onClick={() => editFilterClick(f)}
                                            />
                                        </Tooltip>
                                    );
                                })
                            }
                            <Box sx={{mt: 1}}>
                                <Box maxWidth={'200px'} sx={{display: 'flex', gap: '12px'}}>
                                    <Button
                                        className={'button-purple small'}
                                        onClick={() => setFilterDialogOpen(true)}
                                    >
                                        Add Filter
                                    </Button>
                                    {
                                        unpackedFilters.length > 0 &&
                                        <Button
                                            disabled={name.length === 0}
                                            className={'button-purple small'}
                                            onClick={handleSaveFilter}
                                        >
                                            Save Filter
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    )
                }
            </AccordionDetails>
        )

    }


    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button
                onClick={handleClose}
                text={'Cancel'}
                className={'primary-button'}
            />
            {isConfirmDelete &&
                <Button
                    onClick={handleDelete}
                    text={'Delete Metric'}
                    className={'primary-button button-purple'}
                />
            }
            {!isConfirmDelete &&
                <Button onClick={handleSave}
                        text={'Save'}
                        className={'primary-button button-purple'}
                />
            }
        </Box>
    )

    function handleValidate() {
        server.postData({
            "action": "validateFormula", "formula": metric.formula, "format": metric.format.formatString
        }, (result) => {
            if (result.error) {
                setErrorMessage(result.error);
                setCalculatedValue(null);
            } else if (result.value) {
                setErrorMessage(null);
                setCalculatedValue(result.value);
            } else if (result.success) {
                setErrorMessage(null);
                setCalculatedValue(result.success);
            }
        });

    }

    if (workspaceMetadata && metric && (columnSource.table || metric.derived)) {
        return (
            <Dialog
                open={true}
                onClose={handleClose}
                title={`${isConfirmDelete ? "Delete " : isNew ? "New" : "Edit"} ${metric.derived ? "Formula " : ""}${isConfirmDelete || isNew ? "KPI" : "KPI Properties"}`}
                actions={actions}
                extraWide
            >
                {isConfirmDelete && <>
                    <DialogContentText>
                        Are you sure you want to delete the KPI:
                    </DialogContentText>
                    <Box sx={{display: 'flex', justifyContent: 'center', m: 5}}>
                        {metric.metricName}
                    </Box>
                </>}
                {!isConfirmDelete && <>
                    <DialogContentText>
                        Modify the definition of the KPI
                    </DialogContentText>
                    <Box sx={{minWidth: 700}}>
                        <Input id="metricName"
                               label="KPI Name"
                               fullWidth
                               defaultValue={metric.metricName}
                               onChange={(event) => {
                                   const updatedMetric = {...metric};
                                   updatedMetric.metricName = event.target.value;
                                   setMetric(updatedMetric);
                               }}>
                        </Input>
                    </Box>
                    <Box>
                        <Input
                            id="metricName"
                            label="Format"
                            defaultValue={metric.format ? metric.format.formatString : "#,###"}
                            onChange={(event) => {
                                const updatedMetric = {...metric};
                                updatedMetric.format = {...updatedMetric.format};
                                updatedMetric.format.formatString = event.target.value;
                                setMetric(updatedMetric);
                            }}>
                        </Input>
                    </Box>
                    {metric.derived &&
                        <Input label="Formula" multiline sx={{width: '100%'}} onChange={(event) => {
                            metric = {...metric};
                            metric.formula = event.target.value;
                            setMetric(metric);
                        }} value={metric.formula}/>
                    }
                    {errorMessage && <Typography sx={{fontStyle:'italic', color: 'red'}}>{errorMessage}</Typography>}
                    {calculatedValue && <Typography>Value: {calculatedValue}</Typography>}
                    {metric.derived &&
                        <Button className={`sub-nav-small-button purple`} onClick={handleValidate}>
                            Validate and Test
                        </Button>
                    }
                    {!metric.derived && <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} id="columnSourceDetails">
                            <Typography>Specify the source column to be aggregated for this KPI</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'flex'} gap={'16px'}>
                                <Selector sx={{minWidth: 175}} flex={1}
                                          value={columnSource.inbox || ''}
                                          onChange={(event) => {
                                              columnSource = {...columnSource};
                                              columnSource.inbox = event.target.value;
                                              columnSource.table = columnSource.inbox.tables[0];
                                              columnSource.column = columnSource.table.columns[0];
                                              setColumnSource(columnSource);
                                          }}
                                          label="Source dataset"
                                >
                                    {workspaceMetadata.inboxes?.map((inbox) => {
                                        if (inbox.tables.length === 0) return null;
                                        return (
                                            <MenuItem key={inbox.inboxID} value={inbox}>{inbox.label}</MenuItem>
                                        )
                                    })}
                                </Selector>
                                <Selector sx={{minWidth: 175}}
                                          value={columnSource.table || ''}
                                          onChange={(event) => {
                                              columnSource = {...columnSource};
                                              columnSource.table = event.target.value;
                                              columnSource.column = columnSource.table.columns[0];
                                              setColumnSource(columnSource);
                                          }}
                                          label="Source Table"
                                >
                                    {columnSource.inbox.tables?.map((t) => {
                                        if (t.columns.length === 0) return null;
                                        return (<MenuItem key={t.reportSeriesTableID}
                                                          value={t}>{t.tableName}</MenuItem>)
                                    })}
                                </Selector>
                                <Selector sx={{minWidth: 175}}
                                          value={columnSource.column || ''}
                                          onChange={(event) => {
                                              columnSource = {...columnSource};
                                              columnSource.column = event.target.value;
                                              setColumnSource(columnSource);
                                          }}
                                          label="Source Column"
                                >
                                    {columnSource.table.columns?.map((c) => <MenuItem key={c.columnName}
                                                                                      value={c}>{c.columnName}</MenuItem>)}
                                </Selector>
                            </Box>
                            {columnSource.table.dates &&
                                <Box display={'flex'} gap={'16px'}>
                                    <Selector sx={{minWidth: 175}}
                                              value={columnSource.dateKey ? columnSource.dateKey : 0}
                                              onChange={(event) => {
                                                  columnSource = {...columnSource};
                                                  columnSource.dateKey = event.target.value;
                                                  setColumnSource(columnSource);
                                              }}
                                              label="Choose which report date to use for time series"
                                    >
                                        <MenuItem key={0} value={0}>Snapshot Date</MenuItem>
                                        {columnSource.table.dates?.map((dateName, index) => <MenuItem key={index + 1}
                                                                                                      value={index + 1}>{dateName}</MenuItem>)}
                                    </Selector>
                                </Box>}
                        </AccordionDetails>
                    </Accordion>}
                    {!metric.derived && <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} id="aggregationDetails">
                            <Typography>Define how the column in the table should be aggregated for this
                                KPI</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'flex'} gap={'16px'}>
                                <Selector sx={{minWidth: 175}}
                                          value={!metric.aggRule ? "Sum" : metric.cumulative ? "Cumulative" + metric.aggRule : metric.aggRule}
                                          onChange={(event) => {
                                              const updatedMetric = {...metric};
                                              updatedMetric.aggRule = event.target.value;
                                              setMetric(updatedMetric);
                                          }}
                                          label="Aggregation Type"
                                >
                                    {columnSource.column && columnSource.column.isMeasure &&
                                        <MenuItem value={"Sum"}>Sum</MenuItem>}
                                    <MenuItem value={"Count"}>Count</MenuItem>
                                    <MenuItem value={"CountDistinct"}>Count Distinct</MenuItem>
                                    {columnSource.column && columnSource.column.isMeasure &&
                                        <MenuItem value={"Avg"}>Average</MenuItem>}
                                    {columnSource.column && columnSource.column.isMeasure &&
                                        <MenuItem value={"Min"}>Min</MenuItem>}
                                    {columnSource.column && columnSource.column.isMeasure &&
                                        <MenuItem value={"Max"}>Max</MenuItem>}
                                    {columnSource.column && columnSource.column.isMeasure &&
                                        <MenuItem value={"CumulativeSum"}>CumulativeSum</MenuItem>}
                                    {columnSource.column && columnSource.column.isMeasure &&
                                        <MenuItem value={"CumulativeCount"}>CumulativeCount</MenuItem>}
                                </Selector>

                                <Selector sx={{minWidth: 175}}
                                          value={metric.period || ''}
                                          onChange={(event) => {
                                              const updatedMetric = {...metric};
                                              updatedMetric.period = event.target.value;
                                              setMetric(updatedMetric);
                                          }}
                                          label="Aggregation Period"
                                >
                                    <MenuItem value={"Daily"}>Daily</MenuItem>
                                    <MenuItem value={"Weekly"}>Weekly</MenuItem>
                                    <MenuItem value={"Monthly"}>Monthly</MenuItem>
                                    <MenuItem
                                        value={"Quarterly"}>Quarterly</MenuItem>
                                    <MenuItem value={"Annually"}>Annually</MenuItem>
                                </Selector>

                                <Selector sx={{minWidth: 175}}
                                          value={!metric.calendarType ? "Rolling" : metric.calendarType}
                                          onChange={(event) => {
                                              const updatedMetric = {...metric};
                                              updatedMetric.calendarType = event.target.value;
                                              setMetric(updatedMetric);
                                          }}
                                          label="Period End"
                                >
                                    <MenuItem value={"Rolling"}>Rolling</MenuItem>
                                    <MenuItem value={"Calendar"}>Calendar</MenuItem>
                                </Selector>
                            </Box>
                        </AccordionDetails>
                    </Accordion>}
                    {!metric.derived && workspaceMetadata.filters &&
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} id="filterDetails">
                                <Typography>Use a saved filter to limit the aggregation to specific items</Typography>
                            </AccordionSummary>
                            <FilterAccordion workspaceMetadata={workspaceMetadata}/>
                        </Accordion>
                    }
                    {!metric.derived && <Accordion sx={{marginBottom: '1px'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} id="priorPeriodComparisonDetails"
                                          sx={{marginBottom: '0.5px'}}>
                            <Typography> Specify whether this metric refers to the value in the current period or a
                                value from a prior period</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'flex'} gap={'16px'}>
                                <Selector sx={{minWidth: 150}}
                                          value={metric.shiftPeriod ? metric.shiftPeriod : "Current"}
                                          onChange={(event) => {
                                              const updatedMetric = {...metric};
                                              updatedMetric.shiftPeriod = event.target.value === "Current" ? null : event.target.value;
                                              setMetric(updatedMetric);
                                          }}
                                          label="Prior period"
                                >
                                    <MenuItem value={"Current"}>Current</MenuItem>
                                    {metric.period === "Daily" &&
                                        <MenuItem value={"Daily"}>A number of days ago</MenuItem>}
                                    {(metric.period === "Daily" || metric.period === "Weekly") &&
                                        <MenuItem value={"Weekly"}>A number of weeks ago</MenuItem>}
                                    {(metric.period === "Daily" || metric.period === "Weekly" || metric.period === "Monthly") &&
                                        <MenuItem value={"Monthly"}>A number of months ago</MenuItem>}
                                    {(metric.period === "Daily" || metric.period === "Weekly" || metric.period === "Monthly" || metric.period === "Quarterly") &&
                                        <MenuItem value={"Quarterly"}>A number of quarters ago</MenuItem>}
                                    <MenuItem value={"Annually"}>A number of years ago</MenuItem>
                                </Selector>
                                {metric.shiftPeriod &&
                                    <FormControl className={'input-container'}>
                                        <label className={'inter'} style={{fontSize: '14px'}}>
                                            {"Number of " + getPeriodPlural(metric.shiftPeriod) + " ago"}
                                        </label>
                                        <TextField
                                            value={-(metric.numPeriodsShifted ? metric.numPeriodsShifted : 0)}
                                            className={'input'}
                                            onChange={(event) => {
                                                const updatedMetric = {...metric};
                                                const parsedValue = parseInt(event.target.value, 10); // Parse input value as an integer
                                                updatedMetric.numPeriodsShifted = Number.isInteger(parsedValue) ? -parsedValue : 0;
                                                setMetric(updatedMetric);
                                            }}
                                            sx={{width: '100%', height: 56}}
                                        />
                                    </FormControl>
                                }
                                {metric.shiftPeriod &&
                                    <Selector sx={{minWidth: 150}}
                                              value={metric.metricType || ''}
                                              label="Value To Use"
                                              onChange={(event) => {
                                                  const updatedMetric = {...metric};
                                                  updatedMetric.metricType = event.target.value;
                                                  setMetric(updatedMetric);
                                              }}
                                    >
                                        <MenuItem value={"KPI"}>Value in prior period</MenuItem>
                                        <MenuItem value={"KPIDifferenceFromPriorPeriod"}>Difference between
                                            current
                                            and prior period</MenuItem>
                                        <MenuItem value={"KPIPercentChangeFromPriorPeriod"}>% Difference between
                                            current and prior period</MenuItem>
                                    </Selector>
                                }
                            </Box>
                        </AccordionDetails>
                    </Accordion>}
                </>}
                {
                    filterDialogOpen &&
                    <FilterDialog
                        fromMetricDialog={true}
                        columnSource={columnSource}
                        open={filterDialogOpen}
                        setOpen={setFilterDialogOpen}
                        workspaceMetadata={workspaceMetadata}
                        server={server}
                        config={config}
                        chartProperties={chartProperties}
                        setSelectedFilterColumn={setSelectedFilterColumn}
                        selectedFilterColumn={selectedFilterColumn}
                        resetFilterState={handleResetState}
                        //                        setWorkspaceMetadata={setWorkspaceMetadata}
                        setConfig={setConfig}
                        setEditState={setEditState}
                        editState={editState}
                    />
                }
            </Dialog>
        );
    } else return null;
}

