import {Box, Chip, Divider,Tooltip} from "@mui/material";
import {getStringForFilter, unpackFilters} from "../../../Insights/Filter";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {FilterDialog} from "../../../Insights/FilterDialog";
import {SavedFilterDialog} from "../../../Insights/SavedFilterDialog";
import Button from "../../../common/Button/Button";

export const FilterTab = ({
                              getWorkspaceMetadata,
                              workspaceMetadata,
                              initialSelectedColumn,
                              initialOpen,
                              resetFilterState,
                              analyzeChanges,
                              chartState,
                              setConfig,
                              advanced,
                              server,
                              setTimeRangeDialogOpen,
                              config
                              }) => {

    const [selectedFilterColumn, setSelectedFilterColumn] = useState(null);
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [saveFilterDialogOpen, setSaveFilterDialogOpen] = useState(false);
    const [filterToSave, setFilterToSave] = useState(null);
    const [editState, setEditState] = useState(null);
    console.log('FilterTab', config)
    useEffect(() => {
        setSelectedFilterColumn(initialSelectedColumn !== undefined ? initialSelectedColumn : null)
        setFilterDialogOpen(initialOpen !== undefined ? initialOpen : false)
    }, [initialSelectedColumn, initialOpen])

    const getCustomTimeFilterString = () => {
        let result = "Date: ";
        if (config.from) result += "from " + dayjs(config.from).format('MM/DD/YYYY');
        if (config.to) {
            if (config.from) result += " ";
            result += "to " + dayjs(config.to).format('MM/DD/YYYY');
        }
        return result;
    }

    const deleteFilterClick = (event, filter) => {
        if (filter === "Custom") {
            config.timeRange = "Last Year";
            config.from = null;
            config.to = null;
        } else {
            config.filter = chartState.removeFilterItem(filter);
            config.usedDrillAttributes = config.usedDrillAttributes.filter(att => att !== filter.attributeName)
        }
        chartState.getResults(config);
        setConfig({...config});
    }

    const getColumnType = (attributeName) => {
        const selectedItem = config.selectedItems.find(item =>
            (item.kpi && item.kpi === attributeName) || (item.column && item.column === attributeName)
        );
        if (selectedItem) {
            const table = workspaceMetadata?.inboxes?.forEach(inbox => {
                return inbox.tables.find(table => table.reportSeriesTableID === selectedItem.reportSeriesTableID)
            })
            return table.columns.find(column => column.columnName === attributeName).columnType
        }
    };

    const editFilterClick = (f) => {
        setEditState(f)
        setSelectedFilterColumn({
            columnName: f.attributeName,
            columnType: getColumnType(f.attributeName),
            filterValue: f.filterValue.values
        })
        setFilterDialogOpen(true)
    }

    const unpackedFilters = unpackFilters(chartState.config.filter)

    return (
        <>
            <Box className={'filters-container'}>
                {
                    config.timeRange === "Custom" && (config.from || config.to) &&
                    <Chip
                        key={"Time"}
                        label={getCustomTimeFilterString()}
                        onDelete={(event) => deleteFilterClick(event, "Custom")}
                        onClick={() => setTimeRangeDialogOpen(true)}
                        sx={{m: 0.5}}
                    />
                }
                {
                    unpackedFilters?.map((f) => {
                        let s = getStringForFilter(f);
                        return (
                            <Tooltip title={s} arrow>
                                <Chip

                                    key={s}
                                    label={s}
                                    onDelete={(event) => deleteFilterClick(event, f)}
                                    sx={{m: 0.5}}
                                    onClick={() => editFilterClick(f)}
                                />
                            </Tooltip>
                        );
                    })
                }
                {
                    config.savedFilter &&
                    <Chip
                        key={config.savedFilter.filterName}
                        label={config.savedFilter.filterName + ": " + getStringForFilter(config.savedFilter.filter)}
                        onDelete={() => {
                            config.savedFilter = null;
                            chartState.getResults(config);
                            setConfig({...config});
                        }}
                        sx={{m: 0.5}}
                    />
                }
            </Box>
            {
                unpackedFilters?.length > 0 &&
                <Divider sx={{marginTop: '10px'}}/>
            }
            <Button
                className={'button-purple filter-button'}
                onClick={() => {
                    setSelectedFilterColumn(null)
                    setFilterDialogOpen(true)
                }}
            >
                New Filter
            </Button>
            {
                unpackedFilters?.length > 0 &&
                <Button
                    className={'button-purple filter-button'}
                    onClick={() => {
                        setFilterToSave(chartState.config.filter)
                    }}
                >
                    Save current filter
                </Button>
            }
            <FilterDialog
                open={filterDialogOpen}
                setOpen={setFilterDialogOpen}
                workspaceMetadata={workspaceMetadata}
                server={server}
                selectedFilterColumn={selectedFilterColumn}
                setSelectedFilterColumn={setSelectedFilterColumn}
                config={config}
                setConfig={setConfig}
                chartProperties={chartState}
                analyzeChanges={analyzeChanges}
                resetFilterState={resetFilterState}
                editState={editState}
                setEditState={setEditState}
            />
            <SavedFilterDialog
                dialogOpen={saveFilterDialogOpen}
                filterToSave={filterToSave}
                setFilterToSave={setFilterToSave}
                server={server}
                workspaceMetadata={workspaceMetadata}
                getWorkspaceMetadata={getWorkspaceMetadata}
                config={config}
                setConfig={setConfig}
            />
        </>
    )
}
