import React, {useEffect, useRef, useState, useCallback} from "react";
import './LiveWorksheets.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import {LicenseManager} from 'ag-grid-enterprise'
import {Box, Typography, TextField, InputAdornment, Menu, MenuItem, Popover} from "@mui/material";
import {useApi} from "../../../api/api";
import WorksheetPink from '../../../assets/icons/WorksheetPink.svg'
import PlusWhite from "../../../assets/icons/PlusWhite.svg";
import MagnifyingGlass from "../../../assets/icons/MagnifyingGlass.svg";
import GoogleSheets from "../../../assets/icons/GoogleSheets.png";
import OpenIcon from "../../../assets/icons/ArrowSquareOut.svg";
import CreateIcon from "../../../assets/icons/ArrowSquareOutWhite.svg";
import TrashRed from "../../../assets/icons/TrashRed.svg";
import RefreshInputQueryIcon from "../../../assets/icons/ArrowCounterClockwise.svg";
import Button from "../../common/Button/Button";
import {AgGridReact} from "ag-grid-react";
import en from 'javascript-time-ago/locale/en'
import TimeAgo from "javascript-time-ago";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import IconButton from "@mui/material/IconButton";
import Dialog from "../../common/Dialog/Dialog";
import {useSelector} from "react-redux";
import {debounce} from "lodash";
import {ScoopLoader} from "../../common/Spinner/ScoopLoader";
import PlusRed from "../../../assets/icons/PlusRed.svg";
import Upload from "../../../assets/icons/Upload.svg";
import {DialogItem} from "../../common/DialogItem/DialogItem";
import UploadWorksheet from '../../Objects/UploadWorksheet';
import {Server} from "../../../api/Server";
import {Tab} from "../../common/Tab/Tab";
import {useWorkspaceMetadata} from "../../../hooks/useGetWorkspaceMetadata";
import {TourAlertDialog} from "../../common/Dialog/TourAlertDialog";

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

const tabs = [
    {label: 'All', key: 'all'},
    {label: 'Starred', key: 'starred'},
    {label: 'Recent', key: 'recent'}
]

const NameCellRenderer = ({data}) => {
    const parsedValue = data.name.split('_')
    const label = parsedValue[0]
    const description = parsedValue[1]
    return (
        <div className={'worksheet-cell'}>
            <Typography className={'inter'} sx={{fontSize: '14px', fontWeight: 600}}>{label}</Typography>
            <Typography className={'inter'} sx={{fontSize: '14px', color: '#635566'}}>{description}</Typography>
        </div>
    )
}

const PlatformCellRenderer = ({data}) => {
    return (
        <div className={'worksheet-cell'}>
            <div className={'platform-pill'}>
                {data.platform === 'Google Sheets' && <img src={GoogleSheets} alt={'sheets'}/>}
                <span>{data.platform}</span>
            </div>
        </div>
    )
}

const CreatedByCellRenderer = ({data}) => {
    return (
        <div className={'worksheet-cell'}>
            <Typography className={'inter'} sx={{fontSize: '14px'}}>{data.createdBy}</Typography>
        </div>
    )
}

const LastEditedCellRenderer = ({data}) => {
    return (
        <div className={'worksheet-cell'}>
            <Typography className={'inter'} sx={{fontSize: '14px', color: '#635566'}}>
                {(data.lastEdited ? timeAgo.format(data.lastEdited) : 'No date available')}
            </Typography>
        </div>
    )
}

const MenuCellRenderer = ({data, onDelete}) => {

    const [open, setOpen] = useState(false);
    const anchor = useRef();
    const [refreshLoading, setRefreshLoading] = useState(false)

    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);

    const refreshInputQuery = async () => {

        const server = new Server(workspaceID, userID, token)
        setRefreshLoading(true)
        try {
            const response = await server.sheetPostData({
                'action': 'addOn',
                'addOnAction': 'refreshInputQuery',
                'sheetName': data.name,
                'sheetID': data.worksheetData.worksheetID,
            }, () => {})
        } catch (error) {
            console.log('refreshInputQuery error', error)
        } finally {
            setRefreshLoading(false)
        }
    }

    return (
        <div className={'worksheet-cell'}>
            <IconButton
                onClick={() => setOpen(true)}
                sx={{padding: '4px', borderRadius: '5px', color: 'inherit'}}
                ref={anchor}
            >
                {refreshLoading ? <ScoopLoader size={28}/> :
                    <MoreHorizRoundedIcon fontSize={'small'}/>}
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchor.current}
                onClose={() => setOpen(false)}
            >
                {
                    data?.worksheetData?.url &&
                    <MenuItem onClick={() => {
                        window.open(data.worksheetData.url, "_blank").focus()
                        setOpen(false)
                    }}>
                        <img src={OpenIcon} alt={'open'}/>
                        <Typography className={'inter'} sx={{ml: '8px', fontSize: '14px'}}>Open in Google
                            Sheets</Typography>
                    </MenuItem>
                }
                <MenuItem onClick={() => {
                    refreshInputQuery()
                    setOpen(false)
                }}>
                    <img src={RefreshInputQueryIcon} alt={'trash'} style={{height: 18, width: 18}}/>
                    <Typography className={'inter'} sx={{ml: '8px', fontSize: '14px'}}>Refresh Worksheet</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    onDelete(data.worksheetData.worksheetID)
                    setOpen(false)
                }}>
                    <img src={TrashRed} alt={'trash'} style={{height: 18, width: 18}}/>
                    <Typography className={'inter'} sx={{ml: '8px', fontSize: '14px', color: '#EB382A'}}>Delete Live
                        Worksheet</Typography>
                </MenuItem>
            </Menu>
        </div>
    )
}

export const LiveWorksheets = () => {

    LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-054218}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Scoop_Analytics,_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Scoop_Analytics}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Scoop_Analytics}_need_to_be_licensed___{Scoop_Analytics}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_February_2025}____[v3]_[01]_MTc0MDQ0MTYwMDAwMA==1552ac22a0d8105022c71a9bce2b8bba")
    const userID = useSelector((state) => state.auth.userID);
    const accountID = useSelector((state) => state.auth.accountID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);
    const server = new Server(workspaceID, userID, token);
    const {metadata} = useWorkspaceMetadata(userID, workspaceID, token);

    const userEmail = useSelector(state => state.auth.userEmail);
    const {postData} = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/worksheetactions');
    const gridRef = useRef();
    const [search, setSearch] = useState('');
    const [selectedTab, setSelectedTab] = useState(tabs[0].key);
    const [worksheetToDelete, setWorksheetToDelete] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [worksheetName, setWorksheetName] = useState('');
    const [worksheetDescription, setWorksheetDescription] = useState('');
    const [linkedAccount, setLinkedAccount] = useState(userEmail);
    const [colDefs, setColDefs] = useState([
        {field: "name", cellRenderer: NameCellRenderer, suppressMenu: true},
        {field: "platform", cellRenderer: PlatformCellRenderer, suppressMenu: true},
        {headerName: 'Created By', field: "createdBy", cellRenderer: CreatedByCellRenderer, suppressMenu: true},
        {headerName: 'Last Edited', field: "lastEdited", cellRenderer: LastEditedCellRenderer, suppressMenu: true},
        {
            width: 50,
            cellRenderer: MenuCellRenderer,
            cellRendererParams: {
                onDelete: (worksheetId) => {
                    setWorksheetToDelete(worksheetId)
                    setDeleteDialogOpen(true)
                }
            },
            suppressMenu: true,
            sortable: false
        },
    ]);
    const [rowData, setRowData] = useState([])
    const debounceFn = useCallback(debounce((value) => {
        gridRef.current?.api.setGridOption('rowData', rowData.filter(row => row.name.toLowerCase().includes(value.toLowerCase())))
    }, 1000), [rowData, gridRef.current]);
    const [isLoading, setIsLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [showUploadWorksheet, setShowUploadWorksheet] = useState(false)
    const [openAlertModal, setOpenAlertModal] = useState(false);

    const getSheets = () => {
        setIsLoading(true)
        postData({
            action: 'listDynamicSheets',
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev'
        }).then(r => {
            const rowData = r?.spreadsheets?.map(sheet => ({
                name: sheet.label + '_' + sheet.description,
                platform: sheet.url ? 'Google Sheets' : 'Other',
                createdBy: sheet.firstName + ' ' + sheet.lastName,
                lastEdited: sheet.lastModified,
                worksheetData: sheet
            }))
            setRowData(rowData)
            setLoadingDelete(false)
            setLoadingCreate(false)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (userID) getSheets()
    }, [userID])

    const handleRefresh = () => {
        setShowUploadWorksheet(false); // This will close the dialog
        getSheets(); // Refresh the sheets immediately after closing
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        debounceFn(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleDeleteWorksheet = () => {
        setLoadingDelete(true)
        postData({
            action: 'deleteDynamicSheet',
            worksheetID: worksheetToDelete,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }).then(() => {
            setWorksheetToDelete('')
            setDeleteDialogOpen(false)
            getSheets()
        })
    }

    const handleCloseCreate = () => {
        setWorksheetName('')
        setWorksheetDescription('')
        setLinkedAccount(userEmail)
        setCreateDialogOpen(false)
    }

    const createWorksheetFromUpload = async () => {
        setShowUploadWorksheet(true);
    }

    const closeUploadWorksheet = () => {
        setShowUploadWorksheet(false);
    }

    const handleCreateWorksheet = () => {
        setLoadingCreate(true)
        postData({
            action: "createCalculatedSheet",
            inboxName: worksheetName,
            label: worksheetName,
            googleAccountEmail: linkedAccount,
            userName: linkedAccount,
            derivedFrom: [],
            changesReports: [],
            description: worksheetDescription,
            sortID: "1",
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }).then(() => {
            handleCloseCreate()
            getSheets()
        })
    }

    const deleteActions = (
        <>
            <Button
                onClick={() => {
                    setWorksheetToDelete('')
                    setDeleteDialogOpen(false)
                }}
                text={'Cancel'}
                className={'primary-button'}
            />
            <Button
                onClick={handleDeleteWorksheet}
                className={'primary-button button-purple'}
                disabled={loadingDelete}
            >
                {
                    loadingDelete ? <ScoopLoader/> :
                        <Typography className={'inter'} sx={{fontSize: '14px'}}>Delete</Typography>
                }
            </Button>
        </>
    )

    const createActions = (
        <>
            <Button
                onClick={handleCloseCreate}
                text={'Cancel'}
                className={'primary-button cancel-create'}
            />
            <Button
                onClick={handleCreateWorksheet}
                className={'primary-button button-purple new-worksheet'}
                disabled={!worksheetName || !linkedAccount || loadingCreate}
            >
                {
                    loadingCreate ? <ScoopLoader/> :
                        <>
                            <img src={CreateIcon} alt={'create'}/>
                            <Typography className={'inter'} sx={{fontSize: '14px'}}>Create Worksheet</Typography>
                        </>
                }
            </Button>
        </>
    )

    const renderOptionDialog = () => {

        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{marginTop: '8px', boxShadow: '0px 0px 7px 0px rgba(20, 9, 42, 0.25)'}}
            >
                <Box sx={{padding: '4px 0', borderRadius: '5px'}}>
                    <DialogItem description={'Create new worksheet from scratch'}
                                title={'New Worksheet'}
                                icon={PlusRed}
                                onClick={() => {
                                    setAnchorEl(null)
                                    setCreateDialogOpen(true)
                                }}
                    />
                    <DialogItem description={'Upload your spreadsheet file and start working on it'}
                                title={'Upload existing spreadhseet'}
                                icon={Upload}
                                onClick={createWorksheetFromUpload}
                    />
                </Box>
            </Popover>
        )
    }

    const handleNewLiveWorksheet = (e) => {
        const {workspaceName} = metadata
        if (workspaceName.includes('Tour') && accountID !== 'A1') {
            setOpenAlertModal(true)
        } else {
            setAnchorEl(e.currentTarget)
        }
    }

    return (
        <Box className={'worksheet-screen-container'}>
            <Box className={'worksheets-header'}>
                <Box className={'worksheet-pink'}>
                    <img src={WorksheetPink} alt={'Worksheets'}/>
                </Box>
                <Box className={'header-text'}>
                    <Typography className={'inter'} sx={{fontSize: '28px', fontWeight: 600}}>Live
                        Worksheets</Typography>
                </Box>
                <TextField
                    value={search}
                    placeholder={'Search Worksheets'}
                    onChange={handleSearchChange}
                    variant={'outlined'}
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '38px !important',
                            width: '400px !important',
                            borderRadius: '5px !important',
                            fontFamily: 'Inter, sans-serif'
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <img src={MagnifyingGlass} alt={'search'}/>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button className={'button-purple new-worksheet'} onClick={handleNewLiveWorksheet}>
                    <img src={PlusWhite} alt={'plus'}/>
                    <Typography className={'inter'} sx={{fontSize: '14px'}}>New Live Worksheet</Typography>
                </Button>
            </Box>
            {/*<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>*/}
            {/*    <Box sx={{width: '450px'}}>*/}
            {/*        <Tab*/}
            {/*            tabs={tabs}*/}
            {/*            value={selectedTab}*/}
            {/*            onChange={handleTabChange}*/}
            {/*        />*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            <div className={"ag-theme-alpine"} style={{flex: 1, width: '100%'}}>
                {!isLoading ?
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={colDefs}
                        rowData={rowData}
                        autoSizeStrategy={{type: 'fitGridWidth'}}
                        rowHeight={72}
                        headerHeight={44}
                    /> :
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75%',
                        width: '100%'
                    }}>
                        <ScoopLoader size={72}/>
                    </Box>
                }
            </div>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => {
                    setWorksheetToDelete('')
                    setDeleteDialogOpen(false)
                }}
                title={'Delete Live Worksheet'}
                actions={deleteActions}
            >
                Do you want to delete this Worksheet entirely?
            </Dialog>
            <Dialog
                open={createDialogOpen}
                onClose={handleCloseCreate}
                title={'New Live Worksheet from Scoop'}
                actions={createActions}
            >
                <Box>
                    <Typography className={'inter'} sx={{fontSize: '12px', fontWeight: 600, mb: '4px'}}>Live Worksheet
                        name</Typography>
                    <TextField
                        value={worksheetName}
                        placeholder={'Required'}
                        onChange={e => setWorksheetName(e.target.value)}
                        variant={'outlined'}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '38px !important',
                                width: '450px !important',
                                borderRadius: '5px !important',
                                fontFamily: 'Inter, sans-serif',
                            },
                            '& .MuiInputBase-input': {
                                padding: '0 14px !important'
                            },
                        }}
                    />
                </Box>
                <Box>
                    <Typography sx={{fontSize: '12px', fontWeight: 600, mb: '4px'}}>Description</Typography>
                    <TextField
                        value={worksheetDescription}
                        placeholder={'Optional'}
                        onChange={e => setWorksheetDescription(e.target.value)}
                        variant={'outlined'}
                        multiline
                        rows={3}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '80px !important',
                                width: '450px !important',
                                borderRadius: '5px !important',
                                fontFamily: 'Inter, sans-serif',
                                padding: '0 !important'
                            },
                            '& .MuiInputBase-input': {
                                padding: '0 14px !important'
                            },
                        }}
                    />
                </Box>
                <Box>
                    <Typography className={'inter'} sx={{fontSize: '12px', fontWeight: 600, mb: '4px'}}>Linked
                        Account</Typography>
                    <TextField
                        value={linkedAccount}
                        onChange={e => setLinkedAccount(e.target.value)}
                        variant={'outlined'}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '38px !important',
                                width: '450px !important',
                                borderRadius: '5px !important',
                                fontFamily: 'Inter, sans-serif',
                            },
                            '& .MuiInputBase-input': {
                                padding: '0 14px !important'
                            },
                        }}
                    />
                    <Typography className={'inter'} sx={{fontSize: '12px', color: '#635566', mt: '4px'}}>
                        Want to share with a different account? Update in Settings
                    </Typography>
                </Box>
                <Box className={'info-text-container'}>
                    <Typography className={'inter'} sx={{fontSize: '14px', color: '#635566', mt: '4px'}}>
                        To import your Scoop data to the worksheet, you’ll need to install our plugin. Follow this step
                        by step guide on how to download.
                    </Typography>
                </Box>
            </Dialog>
            {showUploadWorksheet && (
                <UploadWorksheet isOpen={showUploadWorksheet} onClose={closeUploadWorksheet} onRefresh={handleRefresh}/>
            )}
            {renderOptionDialog()}
            <TourAlertDialog open={openAlertModal} setOpen={setOpenAlertModal} item={'live worksheet'} />
        </Box>
    )
}
